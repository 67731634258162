import App from './App/App';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { AuthProvider } from './hooks/Auth/AuthContext';
import { ComponentsProvider } from 'components/hooks/Components/ComponentsContext';
import { InputsProvider } from 'components/hooks/Inputs/InputsContext';
import { ModalProvider } from './components/hooks/Modal/ModalContext';
import { ProfileProvider } from './hooks/Profile/ProfileContext';
import { TablesProvider } from 'components/Tables/TableParts/TableHooks/TablesContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter
    future={{
      v7_relativeSplatPath: true,
      v7_startTransition: true,
    }}
  >
  <ComponentsProvider>
  <AuthProvider>
  <ProfileProvider>
  <TablesProvider>
  <InputsProvider>
  <ModalProvider>
    <App/>
  </ModalProvider>
  </InputsProvider>
  </TablesProvider>
  </ProfileProvider>
  </AuthProvider>
  </ComponentsProvider>
  </BrowserRouter>
);
