import './userPermissions.scss';
import { useParams } from 'react-router-dom';
import { useEffect, useRef } from 'react';
import { text as t} from 'shared/text';
import useAuth from 'hooks/Auth/useAuth';
import useProfile from 'hooks/Profile/useProfile';
import useUtils from 'hooks/useUtils';
import PermissionsUsers from 'pages/Permissions/PermissionsUsers/PermissionsUsers';

const UserPermissions = () => {
    const { id } = useParams();
    const { getAuth } = useAuth();
    const { setHeader, userTabs  } = useProfile();
    const { callAPI, updateUserTabs } = useUtils();
    const runOnce = useRef(false);

    useEffect(() => {
        updateUserTabs(id);
        if (runOnce.current) {return}
        runOnce.current = true;
        callAPI('userDetails', userDetailsCallback, {id: id});
    }, []);

    const userDetailsCallback = (data) => {
        if (data.isSuccessful) {
            const user = data.webPortalUser;
            const header = getAuth()?.id === id ? t.yourProfile : user.name ? user.name : user.email;
            setHeader(header);
        }
    }

    return (
        <PermissionsUsers 
            classes='full-page' 
            subPage={t.permissions} 
            singleUserId={id} 
            userTabs={userTabs}
        />
    )
}

export default UserPermissions;