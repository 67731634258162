import './tableNavBar.scss';
import useProfile from 'hooks/Profile/useProfile';
import TableRowCountPicker from './TableRowCountPicker/TableRowCountPicker';
import TablePageSelector from './TablePageSelector/TablePageSelector';

const TableNavBar = ({id, layout, loading}) => {
    const { size } = useProfile();
    return (
        <div className={`table-nav-bar-container ${layout?.hideNavBar ? 'hide' : ''}`}>
            <div className='table-nav-bar'> 
                {size !== 'phone' && <TableRowCountPicker id={id} layout={layout} loading={loading}/>}
                <TablePageSelector id={id} layout={layout} loading={loading}/>
            </div>
        </div>
    )
}

export default TableNavBar;
