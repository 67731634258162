import { text as t } from 'shared/text';

export const locationPlayerHistoryModalLayout = {
    transactions: {
        tableHeaders: {
            id: t.transactionId,
            terminalId: t.terminalId,
            terminalType: t.terminalType,
            transactionType: t.transactionType,
            cashIn: t.cashIn,
            cashOut: t.cashOut, 
            createdOn: t.createdOn
        },
        sizes: {
            id: 'mid',
            terminalId: 'mid',
            terminalType: 'mid',
            transactionType: 'large',
            cashIn: 'mid',
            cashOut: 'mid', 
            createdOn: 'large'
        },
        layouts: {
            id: 'text-left',
            terminalId: 'text-left',
            terminalType: 'text-left',
            transactionType: 'text-left',
            cashIn: 'blank text-right',
            cashOut: 'blank text-right', 
            createdOn: 'text-left'
        },
        formats: {
            cashIn: 'currency',
            cashOut: 'currency', 
            createdOn: 'date-standard'
        },
        navigation: {
            terminalId: {
                label: 'terminalId',
                data: [
                    {type: 'text', val: 'device'},
                    {type: 'prop', val: 'terminalId'}
                ]
            }
        }
    },

    balances: {
        tableHeaders: {
            id: t.transactionId,
            terminalId: t.terminalId,
            cashableBalance: t.balanceCashable,
            nonCashableBalance: t.balanceNonCashable,
            createdOn: t.createdOn
        },
        sizes: {
            id: 'mid',
            terminalId: 'mid',
            cashableBalance: 'mid',
            nonCashableBalance: 'mid', 
            createdOn: 'large'
        },
        layouts: {
            id: 'text-left',
            terminalId: 'text-left',
            cashableBalance: 'blank text-right',
            nonCashableBalance: 'blank text-right', 
            createdOn: 'text-left'
        },
        formats: {
            cashableBalance: 'currency-decimal',
            nonCashableBalance: 'currency-decimal',
            createdOn: 'date-standard'
        },
        navigation: {
            terminalId: {
                label: 'terminalId',
                data: [
                    {type: 'text', val: 'device'},
                    {type: 'prop', val: 'terminalId'}
                ]
            }
        }
    }    
}