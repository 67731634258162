import './dropdown.scss';

import { useEffect, useRef, useState } from 'react';
import { deepCopy, getObjectLabel, getFormId,
         isValid, sortObjectArrayAsc } from 'components/shared/componentUtils';
import { componentText as t } from 'components/shared/componentData';
import useInputs from '../hooks/Inputs/useInputs';
import DropdownOption from './DropdownOption/DropdownOption';
import Icon from '../Icon/Icon';
import Input from '../Input/Input';

const Dropdown = ({ id, classes,
                    section, line, 
                    index, hideErrors,
                    type, placeholder, 
                    startFocused, masked, 
                    maxLength, disabled,
                    label, hideLabel,
                    valueProp = 'id', 
                    labelProp = 'name', 
                    appendProp, data, 
                    updatesNext, nullable, 
                    noPermission, addCallback, 
                    callback, onBlurCallback }) => {
    const { currentInput, inputValues,
            dropdownDisplays, dropdownOriginals, 
            updateDropdown, updateInput,
            changeCurrentInput, updateInputErrors,
            clearError } = useInputs();
    const [ options, setOptions ] = useState();
    const [ displayedOptions, setDisplayedOptions ] = useState();
    const [ dropdownOpen, setDropdownOpen ] = useState(false);
    const [ mask, setMask ] = useState(masked);
    const [ restoreOriginal, setRestoreOriginal ] = useState(false);
    const [ showAdd, setShowAdd ] = useState(false);
    id = line ? getFormId(section, line, index) : id;
    const original = useRef('');
    const currentInputRef = useRef(currentInput);
    const focusing = useRef();
    const closing = useRef();
    const dropdownEl = useRef(null);
    const inputEl = useRef(null);

    useEffect(() => {
        const list = !data?.length > 0 ? [{type: 'placeholder', label: t.noResults}] : updateOptionsList(deepCopy(data));
        setOptions(list);
    }, [data]);

    useEffect(() => {
        if (!isValid(displayedOptions) || restoreOriginal || disabled) {
            setRestoreOriginal(false);
            return
        };
        if (!isValid(displayedOptions, nullable)) {
            setMask(true);
            return
        } else if (!masked) {
            setMask(false);
        }
    }, [displayedOptions]);

    useEffect(() => {
        if (!options || closing.current) return;
        runDropdownFilter();
    }, [dropdownDisplays[id], options]);
    
    useEffect(() => {
        if (!restoreOriginal) return;
        setRestoreOriginal(false);
        updateInput(id, original.current, true);  // true = dropdown
    }, [restoreOriginal]);

    useEffect(()=>{
        if (original.current !== dropdownOriginals[id]) {
            original.current = dropdownOriginals[id];
        }
    }, [dropdownOriginals[id]])

    useEffect(() => {
        setMask(masked);
    }, [masked]);
    
    const runDropdownFilter = () => {
        let searchVal = dropdownDisplays[id] ?? '';
    
        if (!searchVal || searchVal === original.current) {
            setDisplayedOptions(options);
            return;
        }
        searchVal = searchVal.toUpperCase();
    
        const filteredOptions = options
            .map(item => {
                const label = getObjectLabel(item, labelProp, appendProp)?.toUpperCase() || '';
                const matchIndex = label.indexOf(searchVal);
                return { item, matchIndex };
            })
            .filter(({ matchIndex }) => matchIndex !== -1)
            .sort((a, b) => a.matchIndex - b.matchIndex)
            .map(({ item }) => item);
    
        setDisplayedOptions(filteredOptions.length > 0 ? filteredOptions : [{ type: 'placeholder', label: t.noResults }]);
    }

    const onKeyDown = (event) => {
        if (event.key === 'ArrowDown') {
            event.preventDefault();
            const firstOption = document.querySelector(`#${id}-options .dropdown-option`);
            firstOption?.focus();
        }
    }

    const onOptionsKeyDown = (event) => {
        const key = event.key;
        if (key === 'ArrowDown' || key === 'ArrowUp') {
            event.preventDefault();
            onArrowNav(key === 'ArrowDown' ? 40 : 38);
        } else if (key === 'Enter' || key === 'Tab') {
            const controlled = (key === 'Tab' && (updatesNext || event.shiftKey));
            onEnter(event, key, controlled);
        }
    };

    const onArrowNav = (key) => {
        const field = currentInputRef.current;
        const el = document.activeElement;
        const classes = el.classList;
        let target;
        if (classes.contains('input-display')) {
            const dropdown = document.getElementById(field + '-options');
            target = dropdown?.children[0];
        } else if (classes.contains('dropdown-option')) {
            if (el.dataset.index === '0' && key === 38) {
                target = document.getElementById(field);
            } else {
                key === 40 ? target = el.nextElementSibling : target = el.previousElementSibling
            }
        }
        if (target) {
            target.focus();
        }
    }

    // Open & Closing Animation Functions
    const onFocus = () => {
        if (masked || noPermission || disabled) {return}
        focusing.current = true;
        setTimeout(() => {focusing.current = false}, 250)
        changeCurrentInput(id);
        clearError(id);
        currentInputRef.current = id;
        addCallback && setShowAdd(true);
        if (document.activeElement.classList.contains('dropdown-option')) return;
        if (!dropdownDisplays[id] || dropdownDisplays[id] === original.current) {
            setDisplayedOptions(options);  // Full list if there's no search
        }
        setDropdownOpen(true);
        if (options?.length > 0 && !dropdownOpen) {
            setDropdownOpen(true);
        }
    }

    const onToggleClick = () => {
        if (!focusing.current && !masked && !noPermission && !disabled) {
            if (inputEl.current.closest('.dropdown-container').dataset.open === 'true') {
                closeDropdown();
            } else {
                setDropdownOpen(true)
            }
        }
    }

    const onSelect = ( target ) => {
        const rawVal = target.dataset.value;
        const val = !isValid(rawVal) ? null : isNaN(rawVal) ? rawVal : parseInt(rawVal);
        const label = target.textContent;
        updateDropdown(id, val, label);
        original.current = label;
        const update = {
            id,
            value: val,
            label,
            originalVal: inputValues[id],
            originalLabel: dropdownDisplays[id],
            index,
            ...(section && { section }),
            ...(line && { line }),
            ...(type && { type })
        };    
        callback && callback(update);
    }

    const onEnter = (event, key, controlled) => {
        const el = event.target;
        const classes = el.classList;
        if (classes.contains('dropdown-option')) {
          if (key === 13) {event.preventDefault()};
          if (controlled) {
            event.preventDefault();
          };
          el.click();
        }
    }

    const onAddClick = () => {
        const text = dropdownDisplays?.[id];
        let exists;
        for(let i=0; i<options.length; i++) {
            if (options[i][labelProp ? labelProp : 'label'] === text) {
                exists = true;
                break;
            }
        }
        exists ? updateInputErrors(id, t.errorOptionExists) : addCallback(text);
    }

    const onBlur = () => {
        setTimeout(() => {
            const el = document.activeElement;
            if (dropdownEl?.current?.contains(el)) {
                return;
            }
            currentInputRef.current = null;
            closeDropdown();
        }, 0);
    }

    const closeDropdown = () => {
        closing.current = true; 
        setTimeout(() => { closing.current = false }, 50);
        setRestoreOriginal(dropdownDisplays[id] !== original.current);
        setDropdownOpen(false);
        setShowAdd(false);
        inputEl?.current?.blur();
        onBlurCallback?.([]);
    };
    

    const updateOptionsList = (data) => {
        if (closing.current) {return}
        const sorted = sortObjectArrayAsc(data);
        setDisplayedOptions(sorted);
        return sorted;
    }

    const getNullableLabel = () => {
        return (typeof nullable === 'string') ? nullable : t.all;
    }

    return (
        <div id={`${id}-dropdown`}
            className={[
                'dropdown-container',
                classes || '',
                hideLabel ? 'hide-label' : '',
                hideErrors ? 'hide-errors' : '',
                disabled || noPermission ? 'disabled' : '',
            ].join(' ')}
            disabled={disabled}
            data-open={dropdownOpen}
            data-id={id}
            ref={dropdownEl}
            onBlur={onBlur}
            onClick={onToggleClick}
        >
            <div className='dropdown-display-container'>
                <Input
                    id={id}
                    classes={`dropdown-display ${dropdownOpen ? 'open' : ''} ${placeholder ? 'placeholder' : ''}`}
                    section={section}
                    line={line}
                    index={index}
                    type={type}
                    placeholder={placeholder ? placeholder : (options && options.length === 0) ? t.noResults : t.noSelection}
                    label={label}
                    hideLabel={hideLabel}
                    disabled={disabled}
                    startFocused={startFocused}
                    hideErrors={hideErrors}
                    masked={mask}
                    maxLength={maxLength}
                    dropdown={{
                        inputRef: inputEl,
                        onFocus: onFocus,
                        onKeyDown: onKeyDown
                    }}
                    selectOnClick={true}
                    noPermission={noPermission}
                    blockBlur={true}
                />
                <div 
                    id={id + '-options'} 
                    className={`dropdown-menu ${dropdownOpen ? 'open border' : ''}`}
                    onKeyDown={onOptionsKeyDown}
                >
                    {nullable  && <DropdownOption 
                            item={{value: null, label: getNullableLabel(nullable)}}
                            labelProp={labelProp} 
                            callback={onSelect}/>
                    }
                    {displayedOptions ? displayedOptions.map(( item, i ) => {
                        return <DropdownOption 
                            key={item.key ? item.key : i} 
                            i={i}
                            item={item} 
                            valueProp={valueProp} 
                            labelProp={labelProp} 
                            appendProp={appendProp}
                            callback={onSelect}/>
                    }) : ''}
                </div>
            </div>
            <div 
                className={`dropdown-arrow ${dropdownOpen ? 'open' : ''}`}
                data-dropdown={true}
            >{<Icon icon='caret-down'/>}
            </div>
            {showAdd && <div 
                className={`add-icon`}
                data-dropdown={true}
                onClick={onAddClick}
                >{<Icon icon='plus'/>}
            </div>}
        </div>
    )
}

export default Dropdown;