import './modal.scss'
const Modal = ({classes, title, warning, contentClasses, children}) => {
    return (
        <div className={`modal-backdrop ${classes ? classes : ''}`}>
            <div className={`modal-container`}>
                <div className={`modal-title ${warning ? 'warning' : ''}`}>{title}</div>
                <div className={`modal-content grid ${ contentClasses ? contentClasses : ''}`}>
                    {children}
                </div>
            </div>
        </div>
    )
}

export default Modal;