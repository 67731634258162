import './deviceDetails.scss'
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getFinanceData } from 'shared/utils';
import { deepCopy, getAPIDates, 
         getDefaultDateRange, updateURL } from 'components/shared/componentUtils';
import { text as t } from 'shared/text';
import useInputs from 'components/hooks/Inputs/useInputs';
import useModal from 'components/hooks/Modal/useModal';
import useProfile from 'hooks/Profile/useProfile';
import useUtils from 'hooks/useUtils';
import Button from 'components/Button/Button';
import Card from 'app-components/Cards/Card/Card';
import CashBreakdown from 'components/CashBreakdown/CashBreakdown';
import DateRangePicker from 'components/DateRangePicker/DateRangePicker';
import Dropdown from 'components/Dropdown/Dropdown';
import FinanceBreakdown from 'components/FinancialsBreakdown/FinancialsBreakdown';
import Icon from 'components/Icon/Icon';
import Input from 'components/Input/Input';
import Page from 'app-components/Page/Page';
import NoticeModal from 'modals/FlowModals/NoticeModal/NoticeModal';
import CashDisplay from '../CashDisplay/CashDisplay';

const DeviceDetails = () => {
    const { id } = useParams();
    const { inputValues, clearInput,
            updateNestedInputData } = useInputs();
    const { addModal } = useModal();
    const { size, setHeader, 
            deviceTabs, updateDeviceBreadcrumbs } = useProfile();
    const { callAPI, callAPIGet, 
            updateDeviceTabs,
            hasRank, updateNotice } = useUtils();
    const [ pageData, setPageData ] = useState();
    const [ countryCode, setCountryCode ] = useState();
    const [ boxCount, setBoxCount ] = useState(0);
    const [ locationsList, setLocationsList ] = useState();
    const [ companiesList, setCompaniesList ] = useState();
    const [ statuses, setStatuses ] = useState([]);
    const [ deviceSubtypes, setDeviceSubtypes ] = useState();
    const [ masked, setMasked ] = useState(true);    const [ maskedFinance, setMaskedFinance] = useState([false]);
    const [ maskedCash, setMaskedCash] = useState([true]);
    const [ maskedAssign, setMaskedAssign ] = useState([true]);
    const [ maskedInfo, setMaskedInfo ] = useState([false]);
    const [ tableRecords, setTableRecords ] = useState();
    const [ defaultDates, setDefaultDates ] = useState();
    const [ rawFinanceData, setRawFinanceData ] = useState();
    const [ financials, setFinancials ] = useState();
    const [ cashBreakdown, setCashBreakdown ] = useState();
    const [ typeId, setTypeId ] = useState();
    const deviceType = useRef();
    const machine = useRef();
    const noticeTimeout = useRef();
    const currentLocation = useRef();
    const selectingCompany = useRef(false);
    const dataSet = useRef(false);
    const urlId = 'device-details-' + id;
    const permitted = hasRank('owner');

    const dropdownLists = {
        companyId: {list: 0},
        ['location-id']: {list: 1, nullable: t.noLocation },
        terminalSubClassId: {list: 2, nullable: t.noDeviceSubtype, valueProp: 'terminalSubClassId', labelProp: 'description'}
    }

    useEffect(() => {
        const params = {terminalId: id}
        callAPIGet('deviceDetails', deviceDetailsCallback, params);
        return () => {noticeTimeout.current && clearTimeout(noticeTimeout.current)}
    }, []);

    useEffect(()=>{
        if (!typeId || !size) {return}
        updateDeviceTabs(typeId);
    }, [size, typeId])

    useEffect(()=> {
        if (companiesList && !dataSet.current && pageData && locationsList && deviceSubtypes) {
            dataSet.current = true;
            const updated = updateNestedInputData(pageData, dropdownLists, [companiesList, locationsList, deviceSubtypes]);
            updated && setMasked(false);
        }
    }, [pageData, companiesList, locationsList, deviceSubtypes]);

    useEffect(()=> {
        if (!rawFinanceData || !tableRecords) {return};
        const fin = getFinanceData([deviceType.current], rawFinanceData, tableRecords);
        setFinancials(fin);
        setMaskedFinance([false]);
    }, [rawFinanceData, tableRecords])

    useEffect(()=>{
        if (selectingCompany.current) {
            clearInput(['location-id'], true);
            selectingCompany.current = false;
        }
    }, [inputValues.companyId])

    const deviceDetailsCallback = (data, props) => {
        callAPI('companyDetails', companyDetailsCallback, {id: data?.companyId});
        const type = data.terminalType;
        const typeId = type.id;
        setHeader(type.name + ': ' + data.terminalId);
        updateDeviceBreadcrumbs(data);
        const range = getDefaultDateRange(urlId);
        currentLocation.current = data?.location?.id || null;
        deviceType.current = typeId;
        // GRC Kiosk & Bingo Kiosk, then PPv1 and PPv2
        machine.current = (typeId === 2 || typeId === 7) ? 'recycler' : (typeId === 1 || typeId === 8) ? 'dispenser' : false; 
        setMasked(false);
        setStatuses(data.terminalStatus);
        setPageData(data);
        setBoxCount(data?.cassettes?.length || 0);
        setDefaultDates(range);
        updateURL(urlId, 'dates', range);
        getReportData(range);
        callAPI('deviceSubtypes', deviceSubtypesCallback, {terminalTypeId: type.id});
        setTypeId(typeId);
        if (typeId === 1) {
            setCompaniesList([]);
            setLocationsList([]);
        } else {
            callAPI('companies', companyListCallback, '', props);
            callAPI('locationsByCompany', locationsByCompanyCallback, {companyId: data.companyId, validAddress: true});
        }
    }

    const companyDetailsCallback = (data) => {
        setCountryCode(data?.companyDetailsData?.companyInfo?.country);
    }

    const getReportData = (range) => {
        setMaskedFinance([true]);
        setMaskedCash([true]);
        const params = getAPIDates(range);
        params.terminalId = id;
        callAPIGet('deviceFinancials', deviceFinancialsCallback, params);
        callAPIGet('deviceCashBreakdown', deviceCashBreakdownCallback, params);
        callAPIGet('report-DeviceGames', deviceGamesCallback, params);
    }

    const deviceFinancialsCallback = (data) => {
        setRawFinanceData(data);
    }

    const deviceCashBreakdownCallback = (data) => {
        setMaskedCash([false]);
        setCashBreakdown(data?.cashBreakdown || []);
    }
    
    const deviceGamesCallback = (data) => {
        setMasked(false);
        setTableRecords(data?.data || []);
    }

    const locationsByCompanyCallback = (data, props) => {
        const list = data?.tableData ? data.tableData : [];
        setLocationsList(list);
        if (props?.selecting) {
            const companyId = props.companyId;
            selectingCompany.current = false;
            const newData = deepCopy(inputValues);
            newData.companyId = companyId;
            newData['location-id'] = null;
            updateNestedInputData(newData, dropdownLists, [companiesList, list, deviceSubtypes]);
            selectingCompany.current = true;
        };
        setMaskedAssign([false]);
    }

    const deviceSubtypesCallback = (data, props) => {
        const list = data?.isSuccessful ? data.subClassList : [];
        setDeviceSubtypes(list);
        setMaskedInfo([false]);
        if (props?.adding) {
            updateNotice(data, {id: 'terminalSubClassId'});
        }
    }

    const companyListCallback = (data) => {
        setCompaniesList(data?.companyList ? data.companyList : []);
    }

    const onCompanySelect = (data) => {
        setMaskedAssign([true]);
        const id = data.value;
        const params = {
            companyId: id,
            validAddress: true
        }
        const props = {
            selecting: true,
            companyId: id
        }
        callAPI('locationsByCompany', locationsByCompanyCallback, params, props);
    }

    const onDateRangePick = (range) => {
        updateURL(urlId, 'dates', range);
        getReportData(range, true);
    }

    const onAssign = (event, setLoading) => {
        setLoading(true);
        setMaskedAssign([true]);
        const params = {
            terminalId: id,
            companyId: inputValues.companyId,
            locationId: inputValues['location-id']
        }
        const props = {
            event: event,
            setLoading: setLoading,
            locationId: inputValues['location-id']
        }
        noticeTimeout.current = setTimeout(() => {
            addModal(<NoticeModal header={t.assignmentInProgress} content={t.assignmentNotice}/>)
        }, 3000);
        callAPI('commissionTerminal', commissionTerminalCallback, params, props);
    }

    const commissionTerminalCallback = (data, props) => {
        props.setLoading(false);
        setMaskedAssign([false]);
        clearTimeout(noticeTimeout.current);
        updateNotice(data, props);
        if(data.isSuccessful) {currentLocation.current = props.locationId}
    }

    const onDeviceSubtypeAdd = (text) => {
        setMaskedInfo([true]);
        const params = {
            description: text,
            terminalTypeId: deviceType.current
        }
        const prop = {
            target: document.getElementById('terminalSubClassId')
        }
        callAPI('deviceSubtypeAdd', deviceSubtypeAddCallback, params, prop);
    }

    const deviceSubtypeAddCallback = (data, prop) => {
        setMaskedInfo([false]);
        if (data.isSuccessful) {
            callAPI('deviceSubtypes', deviceSubtypesCallback, {terminalTypeId: deviceType.current}, {adding: true});
        }
        updateNotice(data, prop);
    }

    const onUpdateInfo = (event, setLoading) => {
        setLoading(true);
        setMaskedInfo([true]);
        const serial = inputValues?.machineSerialNumber;
        const ipAddress = inputValues?.ipAddress3rdPartyHostServer;
        const type = inputValues?.terminalSubClassId;
        const params = {
            terminalId: id,
            machineSerialNumber: serial ? serial : '',
            ipAddress3rdPartyHostServer: ipAddress ? ipAddress : '',
        }
        if (deviceType.current === 7) {
            params.salesTaxRate = inputValues.salesTaxRate
        } else {
            params.terminalSubClassId =  type || null
        }
        const props = {
            event: event,
            setLoading: setLoading
        }
        callAPI('deviceInfoUpdate', deviceInfoUpdateCallback, params, props)
    }

    const deviceInfoUpdateCallback = (data, props) => {
        props.setLoading(false);
        setMaskedInfo([false]);
        updateNotice(data, props);
    }

    return (
        <Page
            subPage={t.details}
            tabs={deviceTabs.tabs}
            contentClasses='grid device-details'
        >
            <Card classes='half' label={t.deviceInfo}>
                <Input classes='third display-only' line='platformVersion' label={t.firmwareVersion} masked={masked} hideErrors={true}/>
                <Input classes='third display-only' line='mac' label={t.mac} masked={masked} hideErrors={true}/>
                <Input classes='third display-only' line='ipAddress' label={t.ipAddress} masked={masked} hideErrors={true}/>
                <div className='half device-anydesk-container'> 
                     {hasRank('superuser') && inputValues?.anyDeskNumber && <a href={'anydesk://' + inputValues.anyDeskNumber} className='device-anydesk'><Icon icon='monitor'/></a>}
                    <Input classes='display-only' line='anyDeskNumber' label={t.anyDeskNumber} masked={masked} hideErrors={true}/>
                </div>
                <Input
                    classes='half phone'
                    line='ipAddress3rdPartyHostServer'
                    label={t.thirdPartyIP}
                    placeholder={(deviceType.current === 7 || deviceType.current === 1) ? '' : t.na}
                    masked={masked || maskedInfo?.[0]}
                    hideErrors={true}
                />
                <Input
                    classes='half phone'
                    line='machineSerialNumber'
                    noPermission={!permitted}
                    placeholder={!permitted ? t.nullVal: ''}
                    masked={masked || maskedInfo?.[0]}
                    label={t.serialNumber}
                    hideErrors={true}
                />
                {deviceType.current === 7 ?
                <Input
                    classes='half phone'
                    line='salesTaxRate'
                    type='percentage-decimal'
                    noPermission={!permitted}
                    masked={masked || maskedInfo?.[0]}
                    label={t.salesTaxRate}
                    hideErrors={true}
                />:
                <Dropdown
                    id='terminalSubClassId'
                    classes='half phone'
                    masked={masked || maskedInfo?.[0]}
                    label={t.subtype}
                    data={deviceSubtypes}
                    valueProp='terminalSubClassId'
                    nullable={t.noDeviceSubtype}
                    addCallback={onDeviceSubtypeAdd}
                    hideErrors={true}
                />}                  
                {size !== 'phone' && <Button
                    classes={`green third last`}
                    type='submit'
                    onClick={(event, setLoading)=>onUpdateInfo(event, setLoading)}
                    >{t.updateInfo}
                </Button>}
            </Card>
            <div className='half'>
                <Card classes='full device-statuses-card' label={t.status}>
                    <div className='device-status-label two-thirds'>{t.commissioned}</div>
                    <div className={`device-status-result third ${statuses.includes(6) ? '' : 'success'}`}>
                        {statuses.includes(6) ? t.false : t.true}
                    </div>
                    <div className='device-status-label two-thirds'>{t.inService}</div>
                    <div className={`device-status-result third ${(statuses.includes(4) || statuses.includes(9)) ? '' : 'success'}`}>
                        {statuses.includes(4) ? t.pausedService : statuses.includes(9) ? t.pausedManually : t.true}
                    </div>
                    <div className='device-status-label two-thirds'>{t.authenticated}</div>
                    <div className={`device-status-result third ${statuses.includes(5) ? '' : 'success'}`}>
                        {statuses.includes(5) ? t.false : t.true}
                    </div>
                    <div className='device-status-label two-thirds'>{t.securityCheck}</div>
                    <div className={`device-status-result third ${statuses.includes(3) ? '' : 'success'}`}>
                        {statuses.includes(3) ? t.fail : t.pass}
                    </div>
                    <div className='device-status-label two-thirds'>{t.billAcceptorOperational}</div>
                    <div className={`device-status-result third ${statuses.includes(10) ? '' : 'success'}`}>
                        {statuses.includes(10) ? t.false : t.true}
                    </div>
                </Card>
                {deviceType.current !== 1 && <Card classes='full assignment-top' label={t.assignment}>
                    <Dropdown
                        id='companyId'
                        classes='half phone'
                        label={t.currentCompany}
                        data={companiesList}
                        masked={masked || maskedAssign?.[0]}
                        updatesNext={true}
                        hideErrors={true}
                        callback={onCompanySelect}
                    />
                    <Dropdown
                        section='location'
                        line='id'
                        classes='half phone'
                        label={t.currentLocation}
                        data={locationsList}
                        masked={masked || maskedAssign?.[0]}
                        hideErrors={true}
                        disabled={!locationsList || !inputValues.companyId}
                    />
                    {size !=='phone' && <Button
                        classes='green quarter last'
                        type='submit'
                        disabled={!inputValues.companyId || currentLocation.current === inputValues['location-id']}
                        onClick={(event, setLoading)=>onAssign(event, setLoading)}
                        >{inputValues['location-id'] === null ? t.unassign : t.assign}
                    </Button>}
                </Card>}
            </div>
            {deviceType === 5 ? null :<Card label={'Device Financials'} classes={'device-details-finances-card full'}>
                <DateRangePicker
                    classes={`${(boxCount > 0 || (machine.current && cashBreakdown)) ? 'third' : 'quarter'}`}
                    defaultVal={defaultDates}
                    label={t.dateRange}
                    disabled={masked}
                    callback={onDateRangePick}
                />
                <div className='two-thirds'/>
                <FinanceBreakdown types={[deviceType.current]} classes='quarter' data={financials?.data} masked={maskedFinance?.[0]}/>
                {!machine.current && <CashBreakdown title={t.cashBreakdown} data={cashBreakdown} countryCode={countryCode} classes='quarter' masked={maskedCash?.[0]}/>}
                {(boxCount > 0 || (machine.current === 'dispenser' && cashBreakdown)) && <CashDisplay data={pageData} machine={machine.current}/>}
                {machine.current === 'recycler' && <CashBreakdown title={t.stacker} data={cashBreakdown} countryCode={countryCode} classes='quarter' masked={maskedCash?.[0]}/>}
            </Card>}
        </Page>
    )
}

export default DeviceDetails;
