import { text as t } from 'shared/text'

export const companiesLayout = {
    id: 'companies',
    page: t.companies,

    tableHeaders: {
        name: t.companyName,
        isActive: t.active,
        parent: t.parent,
    },

    sizes: {
        name: 'large',
        isActive: 'mid',
        parent: 'mid',
    },

    layouts: {
        name: 'text-left',
        isActive: 'text-left',
        parent: 'text-left',
    },

    navigation: {
        name: {
            label: 'name',
            data: [
                {type: 'text', val: 'company'},
                {type: 'prop', val: 'id'},
            ]
        },
        parent: {
            label: 'parent.label',
            data: [
                {type: 'text', val: 'company'},
                {type: 'prop', val: 'parent.id'},
            ],
        }
    },
}
