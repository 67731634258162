import './tableSettings.scss';
import { useEffect, useState } from 'react';
import { text as t } from 'shared/text';
import useTable from '../TableHooks/useTables';
import TableCheckbox from '../TableCheckbox/TableCheckbox';

const TableSettings = ({id, layout, open, noURL}) => {
    const { getTableColumns } = useTable();
    const [ columnSelectors, setColumnSelectors ] = useState();

    useEffect(()=> {
        createColumnSelectors();
    }, []);

    const createColumnSelectors = () => {
        const columns = getTableColumns(id, layout);
        const checkboxes = [];
        const headers = layout?.tableHeaders;
        if (!columns) {return}
        columns.forEach((key, i) => {
            checkboxes.push(<TableCheckbox
                key={i}
                id={`${id}-${key}-checkbox`}
                tableId={id}
                tableState='columnsRemoved'
                classes='left'
                prop={key}
                label={headers[key]}
                reversed={true}
            />)
        })
        setColumnSelectors(checkboxes);
    }

    return (
        <div id={id+'-table-settings'} className={`grid table-settings-container ${open ? 'open' : ''}`}>
        <div className='column-picking-section full last grid'>
            <div className='column-picking-title full'>{t.selectTableColumns}</div>
            <div className='column-picking-card full'>
                {columnSelectors}
            </div>
        </div>
    </div>
    )
}

export default TableSettings;
