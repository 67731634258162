import {text as t} from 'shared/text';

export const companyLocationsLayout = {
    tableHeaders: {
        name: t.name,
        id: t.id,
        city: t.city,
        stateCode: t.state,
    },

    sizes: {
        name: 'large',
        id: 'small',
        city: 'small',
        stateCode: 'small',
    },

    layouts: {
        name: 'text-left',
        id: 'text-left phone',
        city: 'text-left',
        stateCode: 'text-left',
    },

    navigation: {
        name: {
            label: 'name',
            data: [
                {type: 'text', val: 'location'},
                {type: 'prop', val: 'id'}
            ]
        }
    },

    rowCount: 20,
}
