import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { text as t } from 'shared/text';
import useComponents from 'components/hooks/Components/useComponents';
import useInputs from 'components/hooks/Inputs/useInputs';
import useProfile from 'hooks/Profile/useProfile';
import useUtils from 'hooks/useUtils';
import Card from 'app-components/Cards/Card/Card';
import Dropdown from 'components/Dropdown/Dropdown';
import Page from 'app-components/Page/Page';
import DeviceTransactionsReport from '../DeviceTransactionsReport';
import DeviceGamesReport from '../DeviceGamesReport';
import DeviceKioskReport from '../DeviceKioskReport';
import DeviceRecyclerHistoryReport from '../DeviceRecyclerHistoryReport';
import DevicePullTabsBoxesReport from '../DevicePullTabsBoxesReport';

const deviceReports = {
    'transactions': <DeviceTransactionsReport/>,
    'games': <DeviceGamesReport/>,
    'kiosk': <DeviceKioskReport/>,
    'recycler': <DeviceRecyclerHistoryReport/>,
    'pullTabs': <DevicePullTabsBoxesReport/>
}

const DeviceReports = () => {
    const { id, type } = useParams();
    const { navTo } = useComponents();
    const { updateDropdown } = useInputs();
    const { size, freeze, deviceTabs } = useProfile();
    const { callAPIGet, updateDeviceTabs } = useUtils();
    const [ reportsList, setReportsList ] = useState();
    const [ currentReport, setCurrentReport ] = useState();
    const [ typeId, setTypeId ] = useState();

    useEffect(() => {   
        updateDropdown('device-reports', type, getDropdownLabel(type));
        setCurrentReport(type);
    }, [])

    useEffect(()=>{
        if (!typeId || !size) {return}
        updateDeviceTabs(typeId);
    }, [size, typeId])

    useEffect(() => {
        updateDropdown('device-reports', type, getDropdownLabel(type));
        setCurrentReport(type ? type : 'transactions');
        const params = {terminalId: id}
        callAPIGet('deviceDetails', deviceDetailsCallback, params);
    }, [type])

    useEffect(() => {
        if (currentReport && type !== currentReport) {
            navTo(`/device/${id}/reports/${currentReport}`);
        }
    }, [currentReport]);

    const deviceDetailsCallback = (data) => {
        const type = data?.terminalType;
        if (!type) {return};
        setTypeId(type.id);
        setReportsList(getReportsList(type.id))
    }

    const getReportsList = (type) => {
        const list = [];
        if (type !== 5) {list.push({value: 'transactions', label: t.transactions})};
        if (type === 3 || type === 6) {list.push({value: 'games', label: t.gamePerformance})};
        if (type === 2) {list.push({value: 'kiosk', label: t.kiosk})};
        if (type === 8 || type === 1) {list.push({value: 'pullTabs', label: t.pullTabsBoxes})};
        return list;
    }

    const onReportSelect = (data) => {
        setCurrentReport(data.value)
    }

    const getDropdownLabel = (val) => {
        switch(val) {
            case 'transactions':
                return t.transactions;
            case 'games':
                return t.gamePerformance;
            case 'kiosk':
                return t.kiosk;
            case 'recycler':
                return t.recyclerHistory;
            case 'pullTabs':
                return t.pullTabsBoxes;
            default:
                return t.transactions;
        }
    }

    return (
        <Page
            subPage={t.reports}
            tabs={deviceTabs.tabs}
            contentClasses='grid device-reports'
        >
            <Card hideLabel={true} classes='full grid margin-bottom'>
                <Dropdown
                    id='device-reports'
                    classes='quarter'
                    label={t.selectedReport}
                    noPermission={reportsList?.length <= 1}
                    masked={freeze}
                    hideErrors={true}
                    data={reportsList}
                    callback={onReportSelect}/>
            </Card>
            {deviceReports[currentReport]}
        </Page>
    )
}

export default DeviceReports;
