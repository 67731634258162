import './gameroomPerformanceReports.scss';
import { useEffect, useState } from 'react';
import { gameroomPerformanceReportsLayout as layout } from './GameroomPerformanceReportsLayout';
import { getDefaultDateRange, getAPIDates } from 'components/shared/componentUtils';
import * as e from 'shared/excel/excel';
import { text as t } from 'shared/text';
import useInputs from 'components/hooks/Inputs/useInputs';
import useProfile from 'hooks/Profile/useProfile';
import useTables from 'components/Tables/TableParts/TableHooks/useTables';
import useUtils from 'hooks/useUtils';
import Button from 'components/Button/Button';
import DateRangePicker from 'components/DateRangePicker/DateRangePicker';
import Dropdown from 'components/Dropdown/Dropdown';
import TableListed from 'components/Tables/TableListed';
const tableId = 'location-gameroom-performance'
const companyId = tableId + '-companyId';

const GameroomPerformanceReports = () => {
    const { updateDropdown, dropdownOriginals } = useInputs();
    const { freeze, setFreeze } = useProfile();
    const { getURLParams, updateTable, 
            updateDropdowns, configureExcelDates, 
            configureExcelReport } = useTables();
    const { callAPI, callAPIGet } = useUtils();
    const [ tableRecords, setTableRecords ] = useState();
    const [ companies, setCompanies ] = useState();
    const [ defaultDates, setDefaultDates ] = useState();
    const [ currentRange, setCurrentRange ] = useState();
    useEffect(() => {
        const range = getDefaultDateRange(tableId);
        setDefaultDates(range);
        setCurrentRange(range);
        getReportData(range);
        callAPI('companies', companiesListCallback);
    }, []);

    useEffect(() => {
        const urlParams = getURLParams(tableId);
        if (urlParams.dropdowns) {
            const sorts = urlParams.dropdowns?.[companyId];
            sorts && updateDropdown(companyId, sorts.value, sorts.label);
        }
    }, [companies])

    const getReportData = (range) => {
        setFreeze(true);
        updateTable('dates', tableId, range);
        const params = getAPIDates(range);
        callAPIGet('report-GameroomPerformance', gamePerformanceReportsCallback, params);
    }

    const gamePerformanceReportsCallback = (data) => {
        setTableRecords(data?.data ? data.data : []);
        setFreeze(false);
    }

    const companiesListCallback = (data) => {
        setCompanies(data?.companyList ? data.companyList : []);
    }

    const onCompanySelect = (data) => {
        data.searchData = {
            dropdownProp: 'label',
            recordProp: 'companyName'
        }
        updateDropdowns(tableId, companyId, data);
    }

    const onDateRangePick = (range) => {
        getReportData(range);
        setCurrentRange(range);
    }

    const onExportToExcel = () => { 
        const title = `${t.gameroomPerformance} ${t.report}`;
        const dateInfo = configureExcelDates(tableId, currentRange, {
            title: title,
            columns: ['companyName', 'startDate', 'endDate'],
            data:[{companyName: dropdownOriginals[companyId] || t.all}]
        });
        layout.formats.locationName = 'text-wrap';
        layout.formats.compnayName = 'text-wrap';
        const [tableInfo, sizes] = configureExcelReport(tableId, t.gamerooms, layout);
        e.exportToFile(title, [dateInfo, tableInfo], sizes);
    }

    return (
        <TableListed
            id={tableId}
            data={tableRecords}
            layout={layout}
            masked={freeze}
            searchBarContent={<>
                <Dropdown
                    id={companyId}
                    classes='quarter'
                    label={t.companyName}
                    masked={freeze}
                    data={companies}
                    nullable={true}
                    hideErrors={true}
                    callback={onCompanySelect}/>
                <DateRangePicker
                    classes='quarter'
                    defaultVal={defaultDates}
                    label={t.dateRange}
                    disabled={freeze}
                    callback={onDateRangePick}/>
                <Button 
                    classes='green sixth match-labeled-input excel-export'
                    disabled={freeze}
                    onClick={onExportToExcel}
                >{t.exportToExcel}</Button>
            </>}
        /> 
    )
}

export default GameroomPerformanceReports;
