import { text as t } from 'shared/text';

export const devicesGRCKiosksLayout = {
    id: 'devices-grc-kiosks',

    tableHeaders: {
        checkbox: '',
        id: t.deviceId,
        machineSerialNumber: t.serialNumber,
        locationName: t.locationName,
        cassettes: [t.recyclers],
        stacker: t.stacker,
        isOnline: t.online
    },

    sizes: {
        checkbox: 'icon',
        id: 'mid',
        machineSerialNumber: 'mid',
        locationName: 'mid',
        cassettes: 'mid',
        stacker: 'mid',
        isOnline: 'small',
    },

    layouts: {
        checkbox: 'phone',
        id: 'text-left',
        machineSerialNumber: 'text-left phone',
        locationName: 'text-left',
        cassettes: 'text-left',
        stacker: 'text-left',
    },
    
    formats: {
        isOnline: 'indicator'
    },

    navigation: {
        id: {
            label: 'id',
            data: [
                {type: 'text', val: 'device'},
                {type: 'prop', val: 'id'},
            ]
        },
        locationName: {
            label: 'locationName',
            data: [
                {type: 'text', val: 'location'},
                {type: 'prop', val: 'locationId'},
            ]
        },
    },

    tooltips: {
        isOnline: {
            prop: 'lastSeenOnline',
            nullVal: t.unknown,
            format: 'readableDateTime',
            prefixes: {
                true: t.onlineNow,
                false: t.lastOnline
            }
        }
    },

    hideSortArrows: {
        checkbox: true,
        cassettes: true,
        stacker: true,
    },

    contextMenu: [
        'reboot',
        'pauseService',
        'startService',
    ]
}
