import { text as t } from 'shared/text';

export const usersLayout = {
    id: 'users',
    page: t.users,

    tableHeaders: {
        name: t.fullName,
        roleName: t.role,
        title: t.title,
        email: t.email,
    },

    sizes: {
        name: 'mid',
        roleName: 'mid',
        title: 'mid',
        email: 'large',
    },

    layouts: {
        name: 'text-left',
        roleName: 'text-left phone',
        title: 'text-left phone',
        email: 'text-left',
    },

    navigation: {
        name: {
            custom: true,
            label: 'name',
            data: [
                {type: 'text', val: 'user'},
                {type: 'prop', val: 'id'}
            ]
        }
    }
}
