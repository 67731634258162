import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { locationGameroomReportLayout as layout } from './layouts/LocationGameroomReportLayout';
import { getDefaultDateRange, getAPIDates } from 'components/shared/componentUtils';
import { getFinanceData, formatGameNames } from 'shared/utils';
import { text as t } from 'shared/text';
import * as e from 'shared/excel/excel';
import useProfile from 'hooks/Profile/useProfile';
import useTables from 'components/Tables/TableParts/TableHooks/useTables';
import useUtils from 'hooks/useUtils';
import Button from 'components/Button/Button';
import Card from 'app-components/Cards/Card/Card';
import CashBreakdown from 'components/CashBreakdown/CashBreakdown';
import DateRangePicker from 'components/DateRangePicker/DateRangePicker';
import FinanceBreakdown from 'components/FinancialsBreakdown/FinancialsBreakdown';
import TableListed from 'components/Tables/TableListed';

const LocationKioskReport = () => {
    const { id } = useParams();
    const { freeze, setFreeze,
            updateLocationBreadcrumbs, updateLocationHeader } = useProfile();
    const { updateTable, configureExcelDates, 
            configureExcelReport } = useTables();
    const { callAPI, callAPIGet } = useUtils();
    const [ countryCode, setCountryCode ] = useState();
    const [ tableRecords, setTableRecords ] = useState();
    const [ currentRange, setCurrentRange ] = useState();
    const [ financials, setFinancials ] = useState();
    const [ cashBreakdown, setCashBreakdown ] = useState();
    const [ defaultDates, setDefaultDates ] = useState();
    const runOnce = useRef();
    const header = useRef();
    const deviceTypes = useRef();
    const tableId = 'location-kiosk-' + id;

    useEffect(() => {
        if (runOnce.current) { return }
        runOnce.current = true;
        layout.customFormats = { gameTypeName: formatGameNames};
        callAPI('locationDetails', locationDetailsCallback, {id: id});
    }, []);

    const locationDetailsCallback = (data) => {
        const details = data?.locationDetails;
        callAPI('companyDetails', companyDetailsCallback, {id: details?.companyId});
        deviceTypes.current = data?.kioskTypes;
        header.current = details.locationName;
        updateLocationHeader(details);
        updateLocationBreadcrumbs(details);
        const range = getDefaultDateRange(tableId);
        setDefaultDates(range);
        setCurrentRange(range)
        getReportData(range);
    }

    const companyDetailsCallback = (data) => {
        setCountryCode(data?.companyDetailsData?.companyInfo?.country);
    }

    const getReportData = (range) => {
        setFreeze(true);
        tableId && updateTable('dates', tableId, range);
        const params = getAPIDates(range);
        params.locationId = id;
        callAPIGet('report-LocationGameroom', gameroomReportCallback, params);
    }

    const gameroomReportCallback = (data) => {
        const records = data?.data ? data.data : [];
        setTableRecords(records);
        setFinancials(getFinanceData(deviceTypes.current, data?.totalFinancialSummary, records));
        setCashBreakdown(data?.totalCashBreakdown || null);
        setFreeze(false);
    }

    const onDateRangePick = (range) => {
        getReportData(range);
        setCurrentRange(range);
    }

    const onExportToExcel = () => {
        const title = `${header.current} ${t.kiosk} ${t.report}`
        const dateInfo = configureExcelDates(tableId, currentRange, {
            title: title,
            columns : ['locationId', 'startDate', 'endDate'],
            data:[{locationId: id}]
        });

        const financeInfo = e.getExcelFinancials(financials);
        const cashInfo = cashBreakdown ? e.getExcelCash(cashBreakdown, countryCode) : null;
        const [tableInfo, sizes] = configureExcelReport(tableId, t.transactions, layout);
        const infoArray = cashBreakdown ? [dateInfo, financeInfo, cashInfo, tableInfo] : [dateInfo, financeInfo, tableInfo]
        e.exportToFile(title, infoArray, sizes);
    }

    return (<div className='full grid'>
        <Card hideLabel={true} classes='full'>
            <DateRangePicker
                classes='quarter'
                defaultVal={defaultDates}
                label={t.dateRange}
                callback={onDateRangePick}
            />
            <Button 
                classes='green sixth match-labeled-input excel-export'
                disabled={freeze}
                onClick={onExportToExcel}
            >{t.exportToExcel}</Button>
        </Card>
        <Card label={t.financials} classes='third' masked={freeze}> 
            <FinanceBreakdown types={deviceTypes.current} classes='full' data={financials?.data} hideTitle={true} tooltip={true}/>
        </Card>
        {cashBreakdown && <Card label={t.cashBreakdown} classes='third' masked={freeze}>
            <CashBreakdown classes='full' data={cashBreakdown} countryCode={countryCode}/>
        </Card>} 
        <div className='table-title full'>{t.gameDetails}</div>
        <TableListed
            id={tableId}
            classes='full'
            data={tableRecords}
            layout={layout}
            masked={freeze}
        />
    </div>)
}

export default LocationKioskReport;
