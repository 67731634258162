import { text as t } from 'shared/text';

export const pullTabsVendorLayout = [
    {
        label: t.pullTabs,
        icon: 'bi-ticket-perforated',
        path: '/pull-tabs',
        rank: 'pullTabsVendor'
    },
];

export const mainMenuLayout = [
    {
        label: t.reports,
        icon: 'bi-file-earmark-bar-graph',
        path: '/reports/company-performance',
        rank: 'all'
    },
    {
        label: t.companies,
        icon: 'bi-building',
        path: '/companies',
        rank: 'superuser'
    },
    {
        label: t.locations,
        icon: 'bi-map',
        path: '/locations',
        rank: 'all'
    },
    {
        label: t.devices,
        icon: 'bi-pc-display-horizontal',
        path: '/devices',
        rank: 'all'
    },
    {
        label: t.users,
        icon: 'bi-people-fill',
        path: '/users',
        rank: 'all'
    },
    {
        label: t.permissions,
        icon: 'bi-key',
        path: '/permissions',
        rank: 'owner',
        classes: 'phone'
    },
    {
        label: t.adminOnly,
        icon: 'bi-shield-lock',
        rank: 'admin',
        children: [
            {
                label: t.deleteAccounts,
                icon: 'bi-trash',
                path: '/delete-accounts',
                rank: 'admin'
            },
            {
                label: t.manifests,
                icon: 'bi-card-list',
                path: '/manifests',
                rank: 'admin'
            },
            {
                label: t.sources,
                icon: 'bi-pc-display-horizontal',
                path: '/sources',
                rank: 'admin'
            },
            pullTabsVendorLayout[0]
        ]
    },
];