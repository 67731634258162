import './financialsBreakdown.scss';
import { useRef } from 'react';
import { isValid, toCurrency } from 'components/shared/componentUtils';
import { getDeviceLabel, getFinanceTypes } from 'shared/utils';
import { text as t } from 'shared/text';
import useAuth from 'hooks/Auth/useAuth';
import useComponents from 'components/hooks/Components/useComponents';

const FinancialsBreakdown = ({types, data, classes, hideTitle, tooltip}) => {
    const { getCountry } = useAuth();
    const { changeTooltipData } = useComponents();
    const country = useRef(getCountry());
    const list = getFinanceTypes(types);

    const getDeviceList = (array, line) =>{
        let devicesList = '';
        const length = array.length;
        if (length <= 1) {return};
        for (let i=0; i<length; i++) {
            const type = array[i];
            // CASES FOR SKIPS
            if (type === 1 && line !== 'cashOut') {continue}; 
            if (line === 'spin' && type !== 3) {continue};
            if ((line === 'reversal' || line === 'promo') && type !== 4 ) {continue};
            if (line === 'credits' && (type < 2  || type > 4)) { continue };
            if (type === 5) {continue};
            // OTHERWISE, ADD A LABEL
            const label = getDeviceLabel(type);
            devicesList = (i > 0 && devicesList)  ? devicesList + ', ' + label : label;
        }
        return devicesList;
    }

    const handleMouseEnter = (id, line) => {
        (tooltip && types?.length > 1) && changeTooltipData({
            id: id,
            type: 'display',
            content: getDeviceList(types, line)
        });
    };
    
    const handleMouseLeave = (event) => {      
        if (!tooltip) {return}
        if (!event.relatedTarget.id?.includes('tooltip')) {
            changeTooltipData(null);
        }
    };
    
    return <div className={`financials-breakdown-container ${classes}`}>
        <div className='financials-breakdown grid'>
            {!hideTitle && <div className='financials-breakdown-title full'>{t.financials}</div>}
            {!list.primepayOnly && <> <div className='half bold'>{t.cashIn}</div> 
                <div className='half val-container'>
                    <div id='financials-cashIn'
                        className={`${data?.cashIn<0 ? 'red-text' : ''}`} 
                        onMouseEnter={() => handleMouseEnter('financials-cashIn', 'cashIn')}
                        onMouseLeave={(event) => handleMouseLeave(event)}
                    >{toCurrency(data?.cashIn, country.current)}</div>
                </div>
            </>}
            <div className='half bold'>{t.cashOut}</div> 
            <div className='half val-container'>
                <div id='financials-cashOut'
                    className={`${data?.cashOut< 0 ? 'red-text' : ''}`}
                    onMouseEnter={() => handleMouseEnter('financials-cashOut', 'cashOut')}
                    onMouseLeave={(event) => handleMouseLeave(event)}
                >{toCurrency(data?.cashOut, country.current)}</div>
            </div>
            {!list.primepayOnly && <><div className='half bold'>{t.profit}</div> 
                <div className='half val-container'>
                    <div id='financials-profit' 
                        className={`${data?.profit< 0 ? 'red-text' : ''}`}
                        onMouseEnter={() => handleMouseEnter('financials-profit', 'profit')}
                        onMouseLeave={(event) => handleMouseLeave(event)}
                    >{toCurrency(data?.profit, country.current)}</div>
                </div>
            </>}
            {!list.primepayOnly && <> <div className='half bold'>{t.hold}</div> 
                <div className='half val-container'>
                    <div id='financials-hold'
                        className={`${data?.hold < 0 ? 'red-text' : ''}`}
                        onMouseEnter={() => handleMouseEnter('financials-hold', 'hold')}
                        onMouseLeave={(event) => handleMouseLeave(event)}
                    >{isValid(data?.hold) ? (data.hold / 100).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + '%'   : t.na}</div>
                </div>
            </>}
            {list.pos && <><div className='half bold'>{t.reversal}</div> 
                <div className='half val-container'>
                    <div id='financials-reversal'
                        className={`${data?.reversal < 0 ? 'red-text' : ''}`}
                        onMouseEnter={() => handleMouseEnter('financials-reversal', 'reversal')}
                        onMouseLeave={(event) => handleMouseLeave(event)}
                    >{toCurrency(data?.reversal, country.current)}</div>
                </div>
            </>}
            {list.pos && <><div className='half bold'>{t.promo}</div> 
                <div className='half val-container'>
                    <div id='financials-promo'
                        className={`${data?.promo< 0 ? 'red-text' : ''}`}
                        onMouseEnter={() => handleMouseEnter('financials-promo', 'promo')}
                        onMouseLeave={(event) => handleMouseLeave(event)}
                    >{toCurrency(data?.promo, country.current)}</div>
                </div>
            </>}
            {(list.grc || list.deluxe || list.pos) && <><div className='half bold'>{t.playerCredits}</div> 
                <div className='half val-container'>
                    <div id='financials-credits' 
                        className={`${data?.escrow< 0 ? 'red-text' : ''}`}
                        onMouseEnter={() => handleMouseEnter('financials-credits', 'credits')}
                        onMouseLeave={(event) => handleMouseLeave(event)}
                    >{toCurrency(data?.escrow, country.current)}</div>
                </div>
            </>}
            {list.deluxe && <><div className='half bold'>{t.spinRate}</div> 
                <div className='half val-container'>
                    <div id='financials-spin' 
                        onMouseEnter={() => handleMouseEnter('financials-spin', 'spin')}
                        onMouseLeave={(event) => handleMouseLeave(event)}
                    >{data?.spinRate}</div>
                </div>
            </>}
        </div>
    </div>
}

export default FinancialsBreakdown;