import {text as t} from 'shared/text';

export const locationDevicesLayout = {
    id: 'locationDevices',

    tableHeaders: {
        id: t.deviceId,
        name: t.name,
        type: t.type,
        cabinetSerial: t.cabinetSerial,
        boardSerial: t.boardSerial,
        refSerial: t.refSerial,
        installDate: t.installDate
    },

    sizes: {
        id: 'mid',
        name: 'small',
        type: 'mid',
        cabinetSerial: 'large',
        boardSerial: 'large',
        refSerial: 'large',
        installDate: 'large',
    },

    layouts: {
        id: 'text-left',
        name: 'text-left phone',
        type: 'text-left',
        cabinetSerial: 'text-left phone',
        boardSerial: 'text-left phone',
        refSerial: 'text-left phone',
        installDate: 'text-left',
    },

    formats: {
        installDate: 'date-standard',
    },

    navigation: {
        id: {
            label: 'id',
            data:  [
                {type: 'text', val: 'device'},
                {type: 'prop', val: 'id'}
            ]
        }
    },

    hideSortArrows: {
        id: true
    }
}
