import './kioskPerformanceReport.scss';
import { useEffect, useState } from 'react';
import { kioskPerformanceReportLayout as layout } from './KioskPerformanceReportLayout';
import { getDefaultDateRange, getAPIDates } from 'components/shared/componentUtils';
import { text as t } from 'shared/text';
import * as e from 'shared/excel/excel';
import useInputs from 'components/hooks/Inputs/useInputs';
import useProfile from 'hooks/Profile/useProfile';
import useTables from 'components/Tables/TableParts/TableHooks/useTables';
import useUtils from 'hooks/useUtils';
import Button from 'components/Button/Button';
import DateRangePicker from 'components/DateRangePicker/DateRangePicker';
import Dropdown from 'components/Dropdown/Dropdown';
import TableListed from 'components/Tables/TableListed';
const tableId = 'kioskPerformanceReport';
const companyId = tableId + '-companyId';

const KioskPerformanceReport = () => {
    const { updateDropdown, dropdownOriginals } = useInputs();
    const { freeze, setFreeze } = useProfile();
    const { getURLParams, updateDropdowns, 
            updateTable, configureExcelDates,
            configureExcelReport } = useTables();
    const { callAPI, callAPIGet } = useUtils();
    const [ companies, setCompanies ] = useState();
    const [ defaultDates, setDefaultDates ] = useState();
    const [ tableRecords, setTableRecords ] = useState();
    const [ currentRange, setCurrentRange ] = useState();

    useEffect(() => {
        const range = getDefaultDateRange(tableId);
        setDefaultDates(range);
        setCurrentRange(range);
        getReportData(range);
        callAPI('companies', companiesCallback);
    }, []);

    useEffect(() => {
        const urlParams = getURLParams(tableId);
        if (urlParams.dropdowns) {
            const sorts = urlParams.dropdowns?.[companyId];
            sorts && updateDropdown(companyId, sorts.value, sorts.label);
        }
    }, [companies]);

    const getReportData = (range) => {
        setFreeze(true);
        updateTable('dates', tableId, range);
        const params = getAPIDates(range);
        callAPIGet('report-KioskPerformance', kioskPerformanceReportCallback, params);
    }
    
    const kioskPerformanceReportCallback = (data) => {
        setTableRecords(data?.data ? data.data : []);
        setFreeze(false);
    }

    const companiesCallback = (data) => {
        setCompanies(data?.companyList ? data.companyList : []);
    }

    const onCompanySelect = (data) => {
        data.searchData = {
            dropdownProp: 'label',
            recordProp: 'companyName'
        }
        updateDropdowns(tableId, companyId, data);
    }

    const onDateRangePick = (range) => {
        setCurrentRange(range);
        getReportData(range);
    }

    const onExportToExcel = () => { 
        const title = `${t.kioskPerformance} ${t.report}`;
        const dateInfo = configureExcelDates(tableId, currentRange, {
            title: title,
            columns: ['companyName', 'startDate', 'endDate'],
            data:[{companyName: dropdownOriginals[companyId] || t.all}],
            configs: {formats: {companyName: 'text-wrap'}},
        });
        layout.formats.companyName = 'text-wrap';
        layout.formats.locationName = 'text-wrap';
        const [tableInfo, sizes] = configureExcelReport(tableId, t.locations, layout);
        e.exportToFile(title, [dateInfo, tableInfo], sizes);
    }

    return (
            <TableListed
                id={tableId}
                data={tableRecords}
                layout={layout}
                masked={freeze}
                searchBarContent={<>
                    <Dropdown
                        id={companyId}
                        classes='quarter'
                        label={t.companyName}
                        hideErrors={true}
                        masked={freeze}
                        data={companies}
                        nullable={t.all}
                        callback={onCompanySelect}
                    />
                    <DateRangePicker
                        id='dateRange'
                        classes='quarter'
                        label={t.dateRange} 
                        disabled={freeze}
                        defaultVal={defaultDates}  
                        callback={onDateRangePick}
                    />
                    <Button 
                        classes='green sixth match-labeled-input excel-export'
                        disabled={freeze}
                        onClick={onExportToExcel}
                    >{t.exportToExcel}</Button>
                </>}
            />
    )
}

export default KioskPerformanceReport;
