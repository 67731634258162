import './login.scss';
import { useEffect, useRef } from 'react';
import { text as t } from 'shared/text';
import useAuth from 'hooks/Auth/useAuth';
import useInputs from 'components/hooks/Inputs/useInputs';
import useProfile from 'hooks/Profile/useProfile';
import Button from 'components/Button/Button';
import Input from 'components/Input/Input';
import Link from 'components/Link/Link';
import logo from 'shared/img/primero-logo.png';
import bg from 'shared/img/login-bg.jpg';

const Login = () => {
    const { callAuth } = useAuth();
    const { inputValues, changeInputErrors,
            clearAllInputs, validateForm } = useInputs();
    const { setUsername, clearAllStates } = useProfile();
    const runOnce = useRef(false);
    const skippedMount = useRef(false);
    const userId = 'portal-login-username';
    const passwordId = 'portal-login-password';

    useEffect(() => {
        if (runOnce.current) { return };
        runOnce.current = true;
        const appElement = document.getElementById('App');
        clearAllStates();
        clearAllInputs();
        Object.assign(appElement.style, {
            backgroundImage: `url(${bg})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center center'
        });
        return(()=>{
            if (skippedMount.current) {
                Object.assign(appElement.style, {});
            } else {
                skippedMount.current = true;
            }
        })
    }, []);

    useEffect(() => {
        document.addEventListener('keydown', onKeyPress);
        return()=>{
            document.removeEventListener('keydown', onKeyPress);
        };
    }, [])

    const onKeyPress = (event) => {
        if (event.key === 'Enter') {
            const submitButton = document.getElementById('login-submit-button');
            submitButton.click();
        }
    }

    const onSubmit = (setLoading) => {
        const user = inputValues[userId];
        const password = inputValues[passwordId];
        const validators = [
            {id: userId, checks: ['required']},
            {id: passwordId, checks: ['required']},
        ]
        const errorsList = validateForm(validators);
        if (errorsList) {
            setLoading(false);
            changeInputErrors(errorsList);
        } else {
            setLoading(true);
            setUsername(user);
            changeInputErrors({});
            const params = {
                organizationId: 1,
                user: user,
                password: password,
                audience: crypto.randomUUID()
            }
            const props = {
                username: user,
                setLoading: setLoading
            }
            callAuth(params, onLoginFail, onAuthFail, props);
        }
    }

    const onLoginFail = (data) => {
        if (!data.accessToken) {
            changeInputErrors({[passwordId]: t.loginFailed});
        }
    }

    const onAuthFail = (err) => {
        const response = err.response;
        const status = response?.status;
        let message;
        switch (status) {
            case 403:
                message = t.loginFailed;
                break;
            case 404:
                message = t.errorNoEndpoint;
                break;
            default:
                message = t.errGeneral;
        }
        changeInputErrors({[passwordId]: message});
    }

    return (
        <div className='login'>
            <div className='login-form-container grid'>
                <div className='login-logo full centered'>
                    <img className='login-logo-image' src={logo} alt='logo'/>
                </div>
                <form className='full grid'>
                    <Input 
                        id={userId}
                        classes='login-input half centered' 
                        label={t.email} 
                        startFocused={true}/>
                    <Input 
                        id={passwordId} 
                        classes='login-input half centered' 
                        type='password'
                        label={t.password}/>
                </form>
                <Button
                    id='login-submit-button'
                    classes='login-submit blue third centered'
                    type='submit'
                    onClick={(event, setLoading) => {onSubmit(setLoading)}}
                >{t.submit}</Button>
                 <div className='login-link-container full centered'>
                     <Link link={'/forgot-password'}>{t.forgotPassword}</Link>
                 </div>

            </div>
        </div>
    )
}

export default Login;
