import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { deviceKioskReportLayout as layout } from './layouts/DeviceKioskReportLayout';
import { getDefaultDateRange, getAPIDates } from 'components/shared/componentUtils';
import { getFinanceData } from 'shared/utils';
import { text as t } from 'shared/text';
import * as e from 'shared/excel/excel';
import useProfile from 'hooks/Profile/useProfile';
import useTables from 'components/Tables/TableParts/TableHooks/useTables'
import useUtils from 'hooks/useUtils';
import Button from 'components/Button/Button';
import Card from 'app-components/Cards/Card/Card';
import CashBreakdown from 'components/CashBreakdown/CashBreakdown';
import DateRangePicker from 'components/DateRangePicker/DateRangePicker';
import FinanceBreakdown from 'components/FinancialsBreakdown/FinancialsBreakdown';
import TableListed from 'components/Tables/TableListed';

const DeviceKioskReport = () => {
    const { id } = useParams();
    const { freeze, setFreeze,
            setHeader, updateDeviceBreadcrumbs } = useProfile();
    const { updateTable, configureExcelDates, 
            configureExcelReport } = useTables();
    const { callAPI, callAPIGet } = useUtils();
    const [ pageData, setPageData ] = useState();
    const [ countryCode, setCountryCode ] = useState();
    const [ tableRecords, setTableRecords ] = useState();
    const [ deviceLocation, setDeviceLocation ] = useState();
    const [ defaultDates, setDefaultDates ] = useState();
    const [ currentRange, setCurrentRange ] = useState();
    const [ financials, setFinancials ] = useState();
    const [ cashBreakdown, setCashBreakdown ] = useState();
    const runOnce = useRef(false);
    const deviceType = useRef();
    const header = useRef();
    const tableId = 'device-kiosk-' + id;

    useEffect(() => {
        if (runOnce.current) {return};
        runOnce.current = true;
        const range = getDefaultDateRange(tableId);
        setDefaultDates(range);
        setCurrentRange(range);
        callAPIGet('deviceDetails', deviceDetailsCallback, {terminalId: id}, range);
    }, []);

    const deviceDetailsCallback = (data, range) => {
        callAPI('companyDetails', companyDetailsCallback, {id: data?.companyId});
        const type = data.terminalType;
        setPageData(data);
        header.current = type ? type.name + ': ' + data.terminalId : data.terminalId
        setHeader(header.current);
        updateDeviceBreadcrumbs(data);
        deviceType.current = type?.id;
        const locationId = data?.location?.id
        setDeviceLocation(locationId);
        getReportData(range, locationId);
    }
    
    const companyDetailsCallback = (data) => {
        setCountryCode(data?.companyDetailsData?.companyInfo?.country);
    }

    const getReportData = (range, locationId) => {
        setFreeze(true);
        updateTable('dates', tableId, range);
        const params = getAPIDates(range);
        params.locationId = deviceLocation ? deviceLocation : locationId;
        callAPIGet('report-LocationGameroom', deviceKiosksCallback, params);
    }

    const deviceKiosksCallback = (data) => {
        setFreeze(false)
        const records = data?.data ? data.data : [];
        setTableRecords(records);
        setFinancials(getFinanceData([deviceType.current], data?.totalFinancialSummary, records ))
        setCashBreakdown(data?.totalCashBreakdown || null);
    }

    const onDateRangePick = (range) => {
        setFreeze(true)
        getReportData(range);
    }

    const onExportToExcel = () => {
        const title = `${header.current} ${t.kiosk} ${t.report}`
        const dateInfo = configureExcelDates(tableId, currentRange, {
            title: title,
            columns: ['locationName', 'startDate', 'endDate'],
            data:[{locationName: pageData?.location?.label}],
            configs: {
                formats: {locationName: 'text-wrap'}
            }
        });
        const financeInfo = e.getExcelFinancials(financials);
        const cashInfo = cashBreakdown ? e.getExcelCash(cashBreakdown, countryCode) : null;
        const [tableInfo, sizes] = configureExcelReport(tableId, t.locations, layout);
        const infoArray = cashBreakdown ? [dateInfo, financeInfo, cashInfo, tableInfo] : [dateInfo, financeInfo, tableInfo]
        e.exportToFile(title, infoArray, sizes)
    }

    return (
        <div className='full grid'>
        <Card hideLabel={true} classes='full'>
            <DateRangePicker
                classes='quarter'
                defaultVal={defaultDates}
                label={t.dateRange}
                disabled={freeze}
                callback={onDateRangePick}
            />
            <Button
                classes='green sixth match-labeled-input excel-export'
                disabled={freeze}
                onClick={onExportToExcel}
            >{t.exportToExcel}</Button>
        </Card>
        <Card label={t.financials} classes='third margin-top' masked={freeze}>
            <FinanceBreakdown types={[deviceType.current]} classes='full' data={financials?.data} hideTitle={true}/>
        </Card>
        {cashBreakdown && <Card label={t.cashBreakdown} classes='third margin-top' masked={freeze}>
            <CashBreakdown classes='full' data={cashBreakdown} countryCode={countryCode}/>
        </Card>}
        <div className='full margin-top'>{t.gameDetails}</div>
        <TableListed
            id={tableId}
            classes='full'
            data={tableRecords}
            layout={layout}
            masked={freeze}
        />
    </div>)
}

export default DeviceKioskReport;
