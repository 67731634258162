import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { deepCopy, toDateStandard } from 'components/shared/componentUtils';
import { deviceControlsLayout as layout } from './DeviceControlsLayout';
import { text as t } from 'shared/text';
import useProfile from 'hooks/Profile/useProfile';
import useUtils from 'hooks/useUtils';
import Card from 'app-components/Cards/Card/Card';
import ContextDropdown from 'app-components/ContextMenu/ContextDropdown/ContextDropdown';
import Page from 'app-components/Page/Page';
import Table from 'app-components/Table/Table/Table';

const controlsLayout = {
    id: 'device-controller-menu',
    contextMenu: [
        'reboot',
        'pauseService',
        'startService',
        'sendLogs',
        'setLocation',
        'executeScript',
        'openSupportTunnel'
    ], // 'resetPOSAdminPassword' is an option
};

const DeviceControls = () => {
    const { callAPI, callAPIGet, 
            callAPIGetBlob, updateDeviceTabs } = useUtils();
    const { id } = useParams();
    const { deviceTabs, setHeader, updateDeviceBreadcrumbs } = useProfile();
    const [ tableData, setTableData ] = useState();
    const [ masked, setMasked ] = useState(true);
    const [ menuLayout, setMenuLayout ] = useState(controlsLayout);
    const runOnce = useRef(false);
    const headers = layout.headers;
    layout.navigation.download.onClick = (pageId, rowData) => {
        callAPIGetBlob('downloadLogFile', downloadLogCallback, '', {id: rowData.id, rowData: rowData})
    }

    useEffect(() => {
        if (runOnce.current) return;
        runOnce.current = true;
        const params = {terminalId: id};
        callAPIGet('deviceDetails', deviceDetailsCallback, params);
        callAPI('deviceLogs', deviceLogsCallback, params);
    }, []);

    const deviceDetailsCallback = (data) => {
        if (!data.isSuccessful) { 
            setMasked(false);
            return;
        }
        const type = data.terminalType;
        updateDeviceTabs(type.id);
        setHeader(type.name + ': ' + data.terminalId);
        updateDeviceBreadcrumbs(data);
        if (type.id === 4 || type.id === 9 ) {
            const newLayout = deepCopy(controlsLayout);
            newLayout.contextMenu.push('resetPOSAdminPassword');
            setMenuLayout(newLayout); 
        }
    }

    const deviceLogsCallback = ( data ) => {
        setTableData(data?.tableData || []);
        setMasked(false);
    } 

    const downloadLogCallback = (blob, props) => {
        const mimeToExtension = {
            'application/x-gtar': 'tar.gz',
            'application/gzip': 'gz',
            'application/zip': 'zip',
            'application/pdf': 'pdf',
            'text/plain': 'txt',
            'application/json': 'json',
            'application/x-tar': 'tar',
            'application/octet-stream': 'bin'
        };
        const extension = mimeToExtension[blob.type] || 'bin';
        const name = `${id}-${toDateStandard(props.rowData.createdOn)}.${extension}`
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = name;
        document.body.appendChild(a);
        a.click();    
        a.remove();
        window.URL.revokeObjectURL(url);
    };
    
    return (
        <Page
            subPage={t.controls} 
            tabs={deviceTabs.tabs} 
            classes={'device-details-controls'}
        >
            <Card classes='full device-commands-card' label={headers.deviceCommands}>
                <ContextDropdown menu={false} classes='third' layout={menuLayout}/>
            </Card>
            <div className='card-label'>{headers.deviceLogs}</div>
            <Table
                data={tableData}
                layout={layout}
                context={useProfile}
                loading={masked}
            />
        </Page>

    )
}

export default DeviceControls;
