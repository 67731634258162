import './qrModal.scss';
import { text as t } from 'shared/text';
import useModal from 'components/hooks/Modal/useModal';
import Button from 'components/Button/Button';
import Modal from 'components/Modal/Modal';

const QRModal = ({content}) => {
    const { removeModal } = useModal();

    return (
        <Modal classes='clear-modal'>
             <img className='qr-image' src={content} alt="QR Code" />
            <Button classes='qr-close green' onClick={()=>{removeModal(false)}}>{t.close}</Button>
        </Modal>
    )
}

export default QRModal;
