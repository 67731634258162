import './tableHeaders.scss';
import { ifElse } from 'components/shared/componentUtils';
import { phoneHeader } from 'components/Tables/tableUtils';
import useProfile from 'hooks/Profile/useProfile';
import useTable from '../TableHooks/useTables';
import TableHeaderCell from './TableHeaderCell/TableHeaderCell';

const TableHeaders = ({id, layout, noURL}) => {
    const { size } = useProfile();
    const { getTableColumns } = useTable();

    const buildHeaderRow = () => {
        if (!layout) {return null}
        const isPhone = size === 'phone'
        const columns = getTableColumns(id, layout, isPhone);
        const headers = ifElse(layout?.tableHeaders);
        const cells = []; 
        columns.forEach((prop, i) => {
            let header = headers[prop];
            if (isPhone) {header = phoneHeader(header)};
            // Seems to be legacy since I can't recall using arrays in tables in ages. Commenting out for now.
            // if (Array.isArray(header)) {
            //     header.forEach((label, j)=>{
            //         if (layout?.layouts?.[prop]?.includes('phone')) {return}
            //         cells.push(<TableHeaderCell key={`${id}-${prop}-${i}-${j}`} id={id} layout={layout} label={label} prop={prop} noURL={noURL}/>);
            //     })
            // } else {
            //     cells.push(<TableHeaderCell key={`${id}-${prop}-${i}`} id={id} layout={layout} label={header} prop={prop} noURL={noURL}/>);
            // }
            cells.push(<TableHeaderCell key={`${id}-${prop}-${i}`} id={id} layout={layout} label={header} prop={prop} noURL={noURL}/>);

        });
        return cells; 
    }

    return (
        <div className={`table-header-row ${layout?.hideSearch ? 'top' : ''}`}>
            {buildHeaderRow()}
        </div>
    );
}

export default TableHeaders;
