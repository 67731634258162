import './table.scss';
import { useEffect, useRef, useState } from 'react';
import { getAPIDates, getDefaultDateRange } from 'components/shared/componentUtils';
import { getPhoneRows } from './tableUtils';
import { text as t } from 'shared/text';
import useAuth from 'hooks/Auth/useAuth';
import useProfile from 'hooks/Profile/useProfile';
import useTables from './TableParts/TableHooks/useTables';
import TableSearchBar from './TableParts/TableSearchBar/TableSearchBar';
import TableHeaders from './TableParts/TableHeaders/TableHeaders';
import TableBody from './TableParts/TableBody/TableBody';
import TableNavBar from './TableParts/TableNavBar/TableNavBar';

const TablePaged = ({id, classes, 
                     layout, defaultDates,
                     searchBarContent, 
                     apiCall, callback }) => {
    const { getCountry } = useAuth();
    const { size, phoneLandScape } = useProfile();
    const { tableSearches, tableSorts,
            tableDropdowns, tableDates, 
            tableToggles, tableRowCounts, 
            tableCurrentPages, 
            updateTableURL, updateTable, 
            updateTableWithURL } = useTables();
    const [ freeze, setFreeze ] = useState(false);
    const noURL = useRef();
    const firstSearch = useRef(true);
    const endpointTimer = useRef();
    const runOnce = useRef();
    const currentSearch = useRef();
    const currentDates = useRef();
    const currentSort = useRef();
    const currentDropdowns = useRef();
    const currentToggles = useRef();
    const currentRowCount = useRef();
    const currentPage = useRef();

    useEffect(() => {
        if (runOnce.current) {return};
        runOnce.current = true;
        setFreeze(true);
        layout.countryCode = getCountry();
        layout.isPhone = size ==='phone';
        updateTable('layouts', id, layout);
        noURL.current = updateTableWithURL(id, layout);
    }, []);

    useEffect(() => {
        if (!defaultDates || !noURL.current || !size) {return};
        currentDates.current = defaultDates;
        const sort = layout.defaultSort ? layout.defaultSort : null;
        currentSort.current = sort;
        const rowCount = size === 'phone' ? getPhoneRows(layout) : layout.defaultRowCount || t.defaultRowCount;
        currentRowCount.current = rowCount;
        const page = 1;
        currentPage.current = page;
        const params = {
            orderBy: sort.orderBy,
            desc: sort.desc,
            rowCount: rowCount,
            currentPage: 1,
            firstRun: true
        }
        if (layout.hasDateRange) {
            params.periodStart = defaultDates[0];
            params.periodEnd = defaultDates[1];
        }
        firstSearch.current = false;
        apiCall(params, apiCallback);
        updateTableURL('dates', defaultDates, id);
    }, [defaultDates, size, phoneLandScape])

    useEffect(() => {
        const search = tableSearches?.[id];
        if (search === undefined) {return};
        if (search === currentSearch.current) {return};
        currentSearch.current = search;
        callEndpoint(false, 300);
    }, [tableSearches?.[id]]);

    useEffect(()=> {
        const dates = tableDates?.[id];
        if (dates === undefined) {return};
        currentDates.current = dates;
        callEndpoint();
    }, [tableDates])

    useEffect(() => {
        const sorts = tableSorts?.[id];
        if (sorts === undefined) {return};
        currentSort.current = sorts || layout.defaultSort;
        callEndpoint(true);
    }, [tableSorts?.[id]])

    useEffect(()=> {
        const dropdowns = tableDropdowns?.[id];
        if (dropdowns === undefined) {return};
        currentDropdowns.current = dropdowns;
        callEndpoint(true);
    }, [tableDropdowns?.[id]])

    useEffect(()=> {
        const toggles = tableToggles?.[id];
        if (toggles === undefined) {return};
        currentToggles.current = toggles;
        callEndpoint();
    }, [tableToggles?.[id]])


    useEffect(() => {
        const counts = tableRowCounts?.[id];
        if (counts === undefined) {return};
        if (counts === currentRowCount.current) {return};
        currentRowCount.current = counts;
        callEndpoint();
    }, [tableRowCounts?.[id]])

    useEffect(() => {
        const page = tableCurrentPages?.[id];
        if (page === undefined) {return};
        if (page === currentPage.current) {return};
        currentPage.current = page;
        updateTable('selectedRows', id, null)
        callEndpoint(true);
    }, [tableCurrentPages?.[id]])
    
    const callEndpoint = (keepPage, time) => {
        clearTimeout(endpointTimer.current);
        setFreeze(true);
        if (!keepPage && !firstSearch.current) {
            updateTable('currentPages', id, 1);
        } 
        endpointTimer.current = setTimeout(() => {
            callAPI(keepPage);
        }, time ? time : 100)
    }

    const callAPI = (keepPage) => {
        const sort = currentSort.current;
        firstSearch.current = false;
        const params = {
            searchTerm: currentSearch.current || '',
            orderBy: sort ? sort.orderBy : layout?.defaultSort?.orderBy,
            desc: sort ? sort.desc : layout.defaultSort.desc,
            rowCount: size === 'phone' ? getPhoneRows(layout) : currentRowCount.current ? currentRowCount.current : t.defaultRowCount,
            currentPage: keepPage ? currentPage.current : 1
        };
        if (layout.hasDateRange) {
            const dates = getAPIDates(getDefaultDateRange(id));
            params.periodStart = dates.periodStart;
            params.periodEnd = dates.periodEnd;
        }
        if (currentDropdowns.current){
            Object.keys(currentDropdowns.current).forEach(key => {
                params[key] = currentDropdowns.current[key].value;
            });
        }
        if (currentToggles.current){
            currentToggles.current.forEach(value => {
                params[value] = true;
            });
        }
        apiCall(params, apiCallback)
    }

    const apiCallback = (data, resetPage) => {
        setFreeze(false);
        updateTable('data', id, data.records);
        updateTable('pageCounts', id, data.pageCount);
        if (data.firstRun) {
            updateTable('checkAlls', id, null);
            updateTable('checks', id, null);
            updateTable('rowCounts', id, data.rowCount);
            updateTable('currentPages', id, data.currentPage);
        }
        if (resetPage) {
            updateTable('currentPages', id, 1);
        }
    }

    return (
        <div id={id} className={`table ${classes ? classes : ''} ${freeze ? 'freeze' : ''} `} >
            <TableSearchBar id={id} layout={layout} searchBarContent={searchBarContent}/>
            <div id={id + '-container'} className={`table-container`}>
                <TableHeaders id={id} layout={layout}/>
                <TableBody id={id} layout={layout} masked={freeze} callback={callback}/>
            </div>
            <TableNavBar id={id} layout={layout} masked={freeze}/>
        </div>
    )
}

export default TablePaged;
