import { useContext } from 'react';
import ProfileContext from 'hooks/Profile/ProfileContext';

const useProfile = () => {
    const { size, setSize,
            phoneLandScape, setPhoneLandscape,
            mainMenuOpen, setMainMenuOpen,
            pageMenuOpen, setPageMenuOpen,
            refreshPage, setRefreshPage,
            freeze, setFreeze,
            username, setUsername,
            header, setHeader,
            tooltipData, setTooltipData,
            headerMessage, setHeaderMessage,
            breadcrumbs, setBreadcrumbs,
            tableStates, setTableStates,
            companyTabs, setCompanyTabs,
            devicesTabs, setDevicesTabs,
            deviceTabs, setDeviceTabs,
            locationTabs, setLocationTabs,
            userTabs, setUserTabs,
            serviceTypes, setServiceTypes,
            sources, setSources,
    } = useContext(ProfileContext);

    const openMainMenu = (a) => {
        if (!a) {
            const menu = document.getElementById('main-menu');
            if (menu) {
                const opened = document.getElementById('main-menu').getElementsByClassName('open');
                opened.length > 0 && opened[0].classList.remove('open');
            }
        }
        setMainMenuOpen(a)
    }

    const openPageMenu = (a) => {
        if (!a) {
            const menu = document.getElementById('page-menu');
            if (menu) {
                const opened = document.getElementById('page-menu').getElementsByClassName('open');
                opened.length > 0 && opened[0].classList.remove('open');
            }
        }
        setPageMenuOpen(a)
    }

    const clearAllStates = () => {
        setTableStates({});
        setDevicesTabs([]);
        setSources(null);
    }

    const updateDeviceBreadcrumbs = (data) => {
        if (!data) {setBreadcrumbs(null)}
        const crumbs = [];
        const companyId = data?.companyId;
        const companyLabel = data?.companyName? data.companyName : companyId
        const locationId = data?.location?.id;
        const locationLabel = data?.location?.label ? data.location.label : locationId;
        companyId && crumbs.push({ url: '/company/' + companyId, label: companyLabel })
        locationId && crumbs.push({ url: '/location/' + locationId, label: locationLabel})
        if (!breadcrumbs || 
            breadcrumbs?.[0]?.url !== crumbs?.[0]?.url || breadcrumbs?.[0]?.label !== companyLabel ||
            breadcrumbs?.[1]?.url !== crumbs?.[1]?.url || breadcrumbs?.[1]?.label !== locationLabel 
        ) { 
            setBreadcrumbs(crumbs) 
        }
    }

    const updateLocationBreadcrumbs = (data) => {
        if (!data) {setBreadcrumbs(null)}
        const crumbs = [];
        const companyId = data?.companyId;
        const companyLabel = data?.companyName? data.companyName : companyId
        companyId && crumbs.push({ url: '/company/' + companyId, label: companyLabel })
        if (!breadcrumbs || breadcrumbs?.[0]?.url !== crumbs?.[0]?.url || breadcrumbs?.[0]?.label !== companyLabel ||
            breadcrumbs?.[1]?.url || breadcrumbs?.[1]?.label
        ) { 
            setBreadcrumbs(crumbs) 
        }
    }

    const updateLocationHeader = (data) => {
        var locationCityState = '';
        if (data?.address?.city && data?.address?.stateCode) {
            locationCityState = " (" + data?.address?.city + ", " + data?.address?.stateCode + ")";
        }
        setHeader(data?.locationName + locationCityState);
    }

    return {
        tableStates, setTableStates,
        size, setSize,
        phoneLandScape, setPhoneLandscape,
        mainMenuOpen, openMainMenu,
        pageMenuOpen, openPageMenu,
        refreshPage, setRefreshPage,
        freeze, setFreeze,
        username, setUsername,
        header, setHeader,
        tooltipData, setTooltipData,
        headerMessage, setHeaderMessage,
        companyTabs, setCompanyTabs,
        devicesTabs, setDevicesTabs,
        deviceTabs, setDeviceTabs,
        locationTabs, setLocationTabs,
        userTabs, setUserTabs,
        serviceTypes, setServiceTypes,
        sources, setSources,  
        breadcrumbs, updateLocationBreadcrumbs, 
        updateDeviceBreadcrumbs,
        clearAllStates, updateLocationHeader
    };
}

export default useProfile;
