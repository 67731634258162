import './locationPlayerHistoryModal.scss';
import { useEffect, useRef, useState } from 'react';
import { getDefaultDateRange, getAPIDates } from 'components/shared/componentUtils';
import { locationPlayerHistoryModalLayout as layout } from './LocationPlayerHistoryModalLayout';
import { text as t } from 'shared/text';
import * as e from 'shared/excel/excel';
import useModal from 'components/hooks/Modal/useModal';
import useTables from 'components/Tables/TableParts/TableHooks/useTables';
import useUtils from 'hooks/useUtils';
import Button from 'components/Button/Button';
import DateRangePicker from 'components/DateRangePicker/DateRangePicker';
import Modal from 'components/Modal/Modal';
import TableListed from 'components/Tables/TableListed';

const LocationPlayerHistoryModal = ({locationId, rowData}) => {
    const { removeModal } = useModal();
    const { updateTable, configureExcelDates, configureExcelReport } = useTables();
    const { callAPI, callAPIGet,  } = useUtils();
    const [ transactions, setTransactions ] = useState();
    const [ balances, setBalances ] = useState();
    const [ defaultDateOne, setDefaultDateOne ] = useState();
    const [ defaultDateTwo, setDefaultDateTwo ] = useState();
    const [ currentRange, setCurrentRange ] = useState();
    const [ tabPage, setTabPage ] = useState(t.playerTransactions)
    const [ maskOne, setMaskOne ] = useState([true]);
    const [ maskTwo, setMaskTwo ] = useState([true]);
    const playerPin = rowData.playerPin;
    const type1 = 'transactions';
    const type2 = 'balances'
    const tableOne = `location-player-${type1}-` + playerPin;
    const tableTwo = `location-player-${type2}-` + playerPin;
    const runOnce = useRef();

    useEffect(() => {
        if (runOnce.current) {return}
        runOnce.current = true;
        const range = getDefaultDateRange(tableOne);
        setDefaultDateOne(range);
        setCurrentRange(range);
        getTransactionsData(range, true);
    }, []);

    const getTransactionsData = (range, first) => {
        setMaskOne([true]);
        updateTable('dates', tableOne, range, true);
        const params = getAPIDates(range);
        params.locationId = locationId;
        params.PlayerPin = playerPin;
        callAPIGet('report-LocationPlayerTransactions', playerTransactionsCallback, params, first);
    }

    const playerTransactionsCallback = (data, first) => {
        setTransactions(data?.playerTransactions || []);
        setMaskOne([false]);
        if (first) {
            const range = getDefaultDateRange(tableTwo);
            setDefaultDateTwo(range);
            getBalancesData(range)
        }
    }

    const getBalancesData = (range) => {
        setMaskTwo([true]);
        updateTable('dates', tableTwo, range, true);
        const params = getAPIDates(range);
        params.locationId = locationId;
        params.playerPin = playerPin;
        callAPI('report-LocationPlayerBalances', playerBalancesCallback, params);
    }

    const playerBalancesCallback = (data) => {
        setBalances(data?.transactions || []);
        setMaskTwo([false]);
    }

    const onDateRangePick = (table, range) => {
        setCurrentRange(range);
        table === 'transactions' 
            ? getTransactionsData(range) 
            : getBalancesData(range);
    }

    const onExportToExcel = (type) => {
        const name = type === type1 ? t.transactions : t.balanceHistory;
        const title = `${t.player} ${playerPin} ${name} ${t.report}`
        const dateInfo = configureExcelDates(tableOne, currentRange, {
            title: title,
            columns: ['locationId', 'startDate', 'endDate'],
            data:[{locationId: locationId}],
        });
        layout[type].formats.locationName = 'text-wrap';
        const [tableInfo, sizes] = configureExcelReport(type === type1 ? tableOne: tableTwo, name, layout[type]);
        e.exportToFile(title, [dateInfo, tableInfo], sizes);
    }

    const onClose = () => {
        removeModal(false);
    }

    return (
        <Modal 
            title={t.player + ': **' + playerPin}
            classes='location-player-history-modal'
        >
            <div className='modal-tab-bar'>
                <div 
                    className={`modal-tab ${tabPage === t.playerTransactions ? 'selected' : ''}`}
                    onClick={()=>{setTabPage(t.playerTransactions)}}
                >
                    {t.playerTransactions}
                </div>
                <div 
                    className={`modal-tab ${tabPage === t.playerBalanceHistory ? 'selected' : ''}`}
                    onClick={()=>{setTabPage(t.playerBalanceHistory)}}
                >
                    {t.playerBalanceHistory}
                </div>
            </div>

            <div className='modal-table-container full'>
                {tabPage === t.playerTransactions &&
                    <TableListed
                        id={tableOne}
                        classes='full'
                        noURL={true}
                        data={transactions}
                        layout={layout[type1]}
                        masked={maskOne?.[0]}
                        searchBarContent={<>
                            <DateRangePicker
                                classes='quarter'
                                defaultVal={defaultDateOne}
                                label={t.dateRange}
                                callback={(data) => {onDateRangePick(type1, data)}}
                            />
                            <Button
                                classes='green sixth match-labeled-input excel-export'
                                disabled={maskOne?.[0]}
                                onClick={()=>{onExportToExcel(type1)}}
                            >{t.exportToExcel}</Button>
                        </>}
                    />
                }
                {tabPage === t.playerBalanceHistory && 
                        <TableListed
                        id={tableTwo}
                        classes='full'
                        noURL={true}
                        data={balances}
                        layout={layout[type2]}
                        masked={maskTwo?.[0]}
                        searchBarContent={<>
                            <DateRangePicker
                                classes='quarter'
                                defaultVal={defaultDateTwo}
                                label={t.dateRange}
                                callback={(data) => {onDateRangePick(type2, data)}}
                            />
                            <Button
                                classes='green sixth match-labeled-input excel-export'
                                disabled={maskTwo?.[0]}
                                onClick={()=>{onExportToExcel(type2)}}
                            >{t.exportToExcel}</Button>
                        </>}
                    />
                }
            </div>
            <div className='grid full location-player-history-modal-buttons'>
                <Button classes='sixth centered' onClick={onClose}>{t.close}</Button>
            </div>
        </Modal>
    )
}

export default LocationPlayerHistoryModal;
