import { text as t } from 'shared/text';

export const deviceKioskReportLayout = {    
    defaultSort: {
        orderBy: 'gameTypeName',
        desc: false,
    },

    tableHeaders: {
        gameTypeName: t.game,
        gameTypePercentage: t.payRate,
        totalPlays: t.plays,
        totalVTP: t.vtp,
        totalWins: t.totalWins,
        profit: t.profit,
        rtp: t.rtp,
        averageBet: t.averageBet,
        numberOfWins: t.winCount,
        averageWin: t.averageWin,
        highestWin: t.highestWin,
        hitFrequencyPercentage: t.hitFrequencyPercentage
    },

    sizes: {
        gameTypeName: 'large',
        gameTypePercentage: 'mid',
        totalPlays: 'mid',
        totalVTP: 'large',
        totalWins: 'large',
        profit: 'large',
        rtp: 'mid',
        averageBet: 'mid',
        numberOfWins: 'mid',
        averageWin: 'mid',
        highestWin: 'large',
        hitFrequencyPercentage: 'large'
    },
    
    layouts: {
        gameTypeName: 'text-left subtotal-label',
        gameTypePercentage: 'text-right phone',
        totalPlays: 'text-right',
        totalVTP: 'text-right phone',
        totalWins: 'text-right phone',
        profit: 'text-right',
        rtp: 'text-right',
        averageBet: 'text-right phone',
        numberOfWins: 'text-right phone',
        averageWin: 'text-right phone',
        highestWin: 'text-right phone',
        hitFrequencyPercentage: 'text-right right-pad phone'
    },

    formats: {
        gameTypePercentage: 'percentage-whole',
        totalPlays: 'number',
        totalVTP: 'currency',
        totalWins: 'currency',
        profit: 'currency',
        rtp: 'percentage',
        averageBet: 'currency',
        numberOfWins: 'number',
        averageWin: 'currency',
        highestWin: 'currency',
        hitFrequencyPercentage: 'percentage'
    },

    subtotals: {
        groupBy: 'gameTypeName',
        fields: {
            gameTypeName: 'header',
            totalPlays: 'total',
            totalVTP: 'total',
            totalWins: 'total',
            profit: 'total',
            rtp: {
                first: {column:'totalWins', type: 'total'},
                 calc: 'divide',
                 second: {column:'totalVTP', type: 'total'}
            },
            averageBet: {
                first: {column:'totalVTP', type: 'total'},
                calc: 'divide',
                second: {column:'totalPlays', type: 'total'},
            },
            numberOfWins: 'total',
            averageWin: {
                first: {column:'totalWins', type: 'total'},
                calc: 'divide',
                second: {column:'numberOfWins', type: 'total'}
            },
            highestWin:'max',
            hitFrequencyPercentage: {
                first: {column:'numberOfWins', type: 'total'},
                calc: 'divide',
                second: {column:'totalPlays', type: 'total'}
            }
        },
        formats: {
            totalPlays: 'number',
            totalVTP: 'currency',
            totalWins: 'currency',
            profit: 'currency',
            rtp: 'percentage-decimal',
            averageBet: 'currency',
            numberOfWins: 'number',
            averageWin: 'currency',
            highestWin: 'currency',
            hitFrequencyPercentage: 'percentage-decimal'
        }
    }
}
