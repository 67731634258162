import { text as t } from 'shared/text'

export const locationPerformanceReportsLayout = {
    defaultSort: {
        orderBy: 'profit',
        desc: true,
    },

    tableHeaders: {
        locationName: t.locationName,
        companyName: t.companyName,
        kioskId: t.device,
        deviceType: t.deviceType,
        stateCode: t.state,
        cashIn: t.cashIn,
        cashOut: t.cashOut,
        promo: t.promo,
        profit: t.profit
    },

    sizes: {
        locationName: 'xl',
        companyName: 'xl',
        kioskId: 'mid',
        deviceType: 'xl',
        stateCode: 'mid',
        cashIn: 'large',
        cashOut: 'large',
        promo: 'large',
        profit: 'xl',
    },

    layouts: {
        locationName: 'text-left',
        companyName: 'text-left',
        kioskId: 'text-left',
        deviceType: 'text-left phone',
        stateCode: 'text-left phone',
        cashIn: 'text-right phone',
        cashOut: 'text-right phone',
        promo: 'text-right phone',
        profit: 'text-right',
    },

    formats: {
        cashIn: 'currency',
        cashOut: 'currency',
        promo: 'currency',
        profit: 'currency',
    },

    navigation: {
        locationName: {
            label: 'locationName',
            data: [
                {type: 'text', val: 'location'},
                {type: 'prop', val: 'locationId'},
            ]
        },
        companyName: {
            label: 'companyName',
            data: [
                {type: 'text', val: 'company'},
                {type: 'prop', val: 'companyId'},
            ]
        },
        kioskId: {
            label: 'kioskId',
            data: [
                {type: 'text', val: 'device'},
                {type: 'prop', val: 'kioskId'},
            ]
        }
    },

    subtotals: {
        groupBy: 'locationName',
        fields: {
            locationName: 'header',
            cashIn: 'total',
            cashOut: 'total',
            promo: 'total',
            profit: 'total',
        },
        formats: {
            cashIn: 'currency',
            cashOut: 'currency',
            promo: 'currency',
            profit: 'currency',
        }
    }
}
