import { text as t } from 'shared/text';

export const deviceRecyclerHistoryReportLayout = {    
    defaultSort:  {
        orderBy: 'createdOn',
        desc: true,
    },

    tableHeaders: {
        id: t.transactionId,
        createdOn: t.createdOn
    },

    sizes: {
        id: 'mid',
        createdOn: 'large'
    },

    layouts: {
        id: 'text-left',
        createdOn: 'text-left'
    },

    formats: {
        createdOn: 'date-standard'
    }
}