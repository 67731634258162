import './input.scss'
import { useEffect, useRef, useState } from 'react';
import { ifElse, isValid, getFormId, 
         controlForPercentage, getInputType } from '../shared/componentUtils';
import { getMaxLength } from '../shared/componentUtils';
import useInputs from '../hooks/Inputs/useInputs';
import useProfile from 'hooks/Profile/useProfile';
import Icon from '../Icon/Icon';

const Input = ({id, section, 
                line, index,
                classes, type, 
                label, hideLabel,
                placeholder, disabled,
                startFocused, dropdown, 
                hideErrors, blockBlur,
                masked, maxLength,
                noPermission, selectOnClick, 
                hideCover, onClickCallback,
                onBlurCallback, callback}) => {
    const { inputValues, updateInput, 
            dropdownDisplays, currentInput, 
            inputErrors, touchscreen, 
            changeTouchScreenState, changeCurrentInput, 
            getInputDisplay, clearError} = useInputs();
    const [ fieldType, setFieldType ] = useState(type === 'password' ? 'password' : 'text');
    const  [maskState, setMaskState ] = useState(masked ? '' : 'faded');
    const [ hideDisplay, setHideDisplay ] = useState(false);
    id = line ? getFormId(section, line, index) : id;
    const inputType = getInputType(type);
    const inputEl = dropdown?.inputRef ||useRef(null);
    const displayValue = inputValues?.[id] ? getInputDisplay(inputValues[id]?.toString(), type) :  '';
    const firstClick = useRef(true);
    const max = getMaxLength(type, maxLength);

    const noCover = () => {
        if (fieldType === 'password' || 
            fieldType === 'password-revealed' || 
            type === 'grc-pin' ||
            type === 'text' || 
            type === 'email' ||
            hideCover) {
                return true
        } else {
            return false;
        }
    }

    const removeCover = (dropdown || noCover()) ? true : false;

    useEffect(()=>{
        startFocused && document.getElementById(id).focus();
    }, [startFocused]);

    useEffect(() => {
        if (masked) {
            setMaskState('');
        } else {
            setMaskState('fading-mask');
            setTimeout(() => setMaskState('faded'), 300);
        }
    }, [masked]);
    
    const onFocus = (event) => {
        dropdown?.onFocus(event)
        setHideDisplay(true);
        if (selectOnClick && firstClick.current) {
            firstClick.current = false;
            inputEl.current.select();
        };
        changeCurrentInput(id);
        onClickCallback?.(event);
    }

    const onKeyDown = (event) => {
        dropdown?.onKeyDown(event);
        const {key} = event;
        if (type === 'percentage' || type === 'max100') {
            controlForPercentage(event, key, inputValues[id] || '');
        }
        if (type === 'decimal' && key === '.' && (inputValues[id] || '').includes('.')) {
            event.preventDefault();
        }
    }

    const onChange = (event) => {
        clearError(id);
        let val = event.target.value;
        updateInput(id, val, dropdown);
        callback && callback({
            section: section,
            line: line,
            id: id,
            value: val
        });
    };

    const onBlur = () => {
        setHideDisplay(false);
        firstClick.current = true;
        if (blockBlur || touchscreen) {return};
        if (id === currentInput) {changeTouchScreenState(null)};
        onBlurCallback && onBlurCallback(
            {
                section: section,
                line: line,
                id: id,
                value: inputValues[id]
            }
        )
    }

    const onPasswordReveal = () => {
        setFieldType('password-revealed');
    }

    const onPasswordHide = () => {
        setFieldType('password');
    }

    return (
        <div id={`${id}-input`}
            className={[
            "input-container", classes || '',
            hideLabel ? 'hide-label' : '',
            hideErrors ? 'hide-errors' : '',
            disabled ? 'disabled' : '',
            inputErrors?.[id] ? 'error' : '',
            (inputType === 'number' && type !== 'phone' && type !== 'zip') ? 'right' : '',
            noPermission ? 'display-only' : '',
            masked ? 'masked' : ''
        ].join(' ')}>
            <div className={`input-label`}>{label}</div>
            <div className={`input-mask ${maskState}`}/> 
            <div className= {`input-cover-display ${(placeholder && !displayValue) ? 'placeholder' : ''}`} onClick={(event)=>{onFocus(event)}}>
                {displayValue ? displayValue : placeholder ? placeholder : ''}{type === 'percentage' && displayValue ? '%' : ''}
            </div>
            <input
                id={id}
                ref={inputEl}
                className={`input-display 
                    ${(hideDisplay || removeCover)? 'no-cover' : ''}
                    ${type === 'allCaps' ? 'allCaps' : ''} ${!isValid(inputValues?.[id] || !isValid(dropdownDisplays)) ? 'placeholder' : ''}`}
                disabled={disabled}
                placeholder={placeholder ? placeholder : ''}
                data-dropdown={dropdown ? true : false}
                type={fieldType}
                data-type={type}
                maxLength={maxLength !== 'none' ? (maxLength || max) : undefined}
                data-max-length={maxLength !== 'none' ? (maxLength || max) : 'none'}
                autoComplete='off'
                autoCapitalize={type === 'allCaps' ? 'characters' : type === 'firstCap' ? 'sentence' : ''}
                tabIndex={(disabled || classes?.includes('display-only') || masked || noPermission) ? -1 : 0}
                value={dropdown ? ifElse(dropdownDisplays?.[id]) : ifElse(inputValues?.[id])}
                onKeyDown={onKeyDown}
                onChange={(event)=>{onChange(event)}}
                onFocus={onFocus}
                onBlur={onBlur}/>
            {(fieldType === 'password') && !noPermission && <div className='input-password-reveal-button' onClick={onPasswordReveal}><Icon icon='eye' classes='clickable'/></div>}
            {(fieldType === 'password-revealed') && !noPermission && <div className='input-password-reveal-button' onClick={onPasswordHide}><Icon icon='eye-slash' classes='clickable'/></div>}
            <div className={`input-error-message ${(inputErrors?.[id]) ? 'show' : ''}`}>{inputErrors?.[id]}</div>
        </div>
    )
}

export default Input;