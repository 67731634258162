import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getDefaultDateRange, getAPIDates } from 'components/shared/componentUtils';
import { locationPlayerInfoReportLayouts as layout } from './layouts/LocationPlayerInfoReportLayouts';
import { text as t } from 'shared/text';
import * as e from 'shared/excel/excel';
import useProfile from 'hooks/Profile/useProfile';
import useTables from 'components/Tables/TableParts/TableHooks/useTables';
import useUtils from 'hooks/useUtils';
import Button from 'components/Button/Button';
import DateRangePicker from 'components/DateRangePicker/DateRangePicker';
import TableListed from 'components/Tables/TableListed';

const LocationPlayerInfoReport = () => {
    const { id } = useParams();
    const { freeze, setFreeze,
            updateLocationBreadcrumbs, updateLocationHeader } = useProfile();
    const { updateTable, configureExcelDates, configureExcelReport } = useTables();
    const { callAPI, callAPIGet } = useUtils();
    const [ tableRecords, setTableRecords ] = useState();
    const [ defaultDates, setDefaultDates ] = useState();
    const [ resultsVals, setResultsVals ] = useState();
    const [ currentRange, setCurrentRange ] = useState();
    const runOnce = useRef();
    const header = useRef();
    const tableId = 'location-player-info-' + id;

    useEffect(() => {
        if (runOnce.current) {return}
        runOnce.current = true;
        const range = getDefaultDateRange(tableId);
        setDefaultDates(range);
        setCurrentRange(range);
        getReportData(range);
        callAPI('locationDetails', locationDetailsCallback, {id: id});
    }, []);

    const locationDetailsCallback = (data) => {
        const details = data?.locationDetails;
        header.current = details.locationName;
        updateLocationBreadcrumbs(details);
        details && updateLocationHeader(details);
    }

    const getReportData = (range) => {
        setFreeze(true);
        updateTable('dates', tableId, range);
        const params = getAPIDates(range);
        params.locationId = id;
        callAPIGet('report-LocationPlayerInfo', playerInfoCallback, params);
    }

    const playerInfoCallback = (data) => {
        setResultsVals({
            activePlayers: data?.totalActivePlayers,
            newPlayers: data?.totalNewPlayers
        })
        setTableRecords(data?.data ? data.data : []);
        setFreeze(false);
    }

    const onDateRangePick = (range) => {
        getReportData(range);
        setCurrentRange(range);
    }

    const onExportToExcel = () => {
        const title = `${header.current} ${t.playerInfo} ${t.report}`;
        const dateInfo = configureExcelDates(tableId, currentRange, {
            title: title,
            columns: ['locationId', 'startDate', 'endDate'],
            data:[{locationId: id}],
        });
        const [tableInfo, sizes] = configureExcelReport(tableId, t.playerInfo, layout);
        e.exportToFile(title, [dateInfo, tableInfo], sizes);
    }

    return (<div className='full grid'>
       <div className='table-title full no-margin-top'>{t.playerInfo}</div>
        <TableListed
            id={tableId}
            classes='full'
            data={tableRecords}
            layout={layout}
            masked={freeze}
            resultsValues={resultsVals}
            searchBarContent={<>
                <DateRangePicker
                    classes='quarter'
                    defaultVal={defaultDates}
                    label={t.dateRange}
                    callback={onDateRangePick}
                />
                <Button
                    classes='green sixth match-labeled-input excel-export'
                    disabled={freeze}
                    onClick={onExportToExcel}
                >{t.exportToExcel}</Button>
            </>}
        />
    </div>)
}

export default LocationPlayerInfoReport;
