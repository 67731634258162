import './serviceItem.scss';
import { useEffect, useRef, useState } from 'react';
import { text as t } from 'shared/text';
import useInputs from 'components/hooks/Inputs/useInputs';
import useProfile from 'hooks/Profile/useProfile';
import useUtils from 'hooks/useUtils';
import Input from 'components/Input/Input';
import Checkbox from 'components/Checkbox/Checkbox';
import primepay from 'shared/img/services/primepay.png';
import goldrushcity from 'shared/img/services/goldrushcity.png';
import piggybank from 'shared/img/services/piggybankdeluxe.png';
import bingo from 'shared/img/services/bingo.png';
import gameroomPOS from 'shared/img/services/gameroomPOS.png';

const ServiceItem = ({ prop, classes, masked, callback}) => {
    const { inputValues, inputErrors } = useInputs();
    const { size } = useProfile();
    const { hasRank } = useUtils();
    const [ phone, setPhone ] = useState(size === 'phone');
    const permitted = hasRank('owner', true);
    const isPrimero = hasRank('superuser', true);
    const isActive = useRef();

    useEffect(() => {
        isActive.current = inputValues[prop + '-active']
    }, [inputValues])

    const images = {
        primePay: primepay,
        sweepsKiosk: goldrushcity,
        connectedGames: piggybank,
        bingoKiosk: bingo,
        gameRoomPOS: gameroomPOS,
    };

    const labels = {
        primePay: t.primePayLabel,
        sweepsKiosk: t.goldRushCityKiosk,
        bingoKiosk: t.bingoKiosk,
        connectedGames: t.connectedGames,
        gameRoomPOS: t.gameroomPOS
    }

    return (
        <div className={`service-item ${classes ? classes : ''}`}>
            <div className={`service-item-contents ${(!isActive.current && !isPrimero) ? 'disabled' : ''}`}>
                <div className='service-item-label'>{labels[prop]}</div>
                <div className='service-item-logo-container'>
                    <img className='service-item-logo' src={images[prop]} alt='logo'/>
                </div>
                <div className='service-item-input-container grid'>
                    <Checkbox 
                        section={prop} line='active' 
                        classes={`half right centered ${!isPrimero ? 'display-only' : ''}`} 
                        disabled={phone}
                        label={t.enabled} />
                    {prop !== 'primePay' && <Input 
                        section={prop} line='licenseRate' 
                        type='percentage'
                        classes='two-thirds centered' 
                        label={t.licenseRate} 
                        masked={masked}
                        noPermission={!isPrimero} 
                        hideErrors={true} 
                        callback={callback} 
                        disabled={!isActive.current || phone}
                    />}
                    {prop !== 'primePay' && <Input 
                        section={prop} line='operatorRate'
                        classes='two-thirds centered' 
                        type='percentage' 
                        label={t.operatorRate} 
                        masked={masked} 
                        noPermission={!permitted} 
                        hideErrors={true}
                        callback={callback} 
                        disabled={!isActive.current || phone}/>}
                    {prop !== 'primePay' && <Input 
                        section={prop} line='locationRate'
                        classes='two-thirds centered'  
                        type='percentage'
                        label={t.locationRate} 
                        masked={masked} 
                        noPermission={!permitted} 
                        hideErrors={true} 
                        callback={callback} 
                        disabled={!isActive.current || phone}/>}
                    <div className='two-thirds'></div><div className='service-item-error full'>{inputErrors?.[prop + '-operatorRate']}</div>
                </div>
            </div>
            {(!isActive.current && !isPrimero) && <div className='service-item-banner'>
                <span className='service-item-banner-text'>{t.serviceItemAd}</span>
            </div>} 
        </div>
    )
}

export default ServiceItem;