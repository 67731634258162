import './table.scss';
import { useEffect, useRef, useState } from 'react';
import { sortList } from 'components/shared/componentUtils';
import { getKeys, getSubtitleContent, 
         getSubtotalContent, filterByInput } from './tableUtils';
import useAuth from 'hooks/Auth/useAuth';
import useProfile from 'hooks/Profile/useProfile';
import useTables from './TableParts/TableHooks/useTables';
import TableSearchBar from './TableParts/TableSearchBar/TableSearchBar';
import TableHeaders from './TableParts/TableHeaders/TableHeaders';
import TableBody from './TableParts/TableBody/TableBody';
import TableTotals from './TableParts/TableTotals/TableTotals';

const TableListed = ({id, classes, 
                      layout, data, 
                      noURL, masked, 
                      resultsValues, callback,
                      searchBarContent }) => {
    const { getCountry } = useAuth();
    const { size } = useProfile();
    const { tableSearches, tableSorts, 
            tableDropdowns, updateTable, 
            updateTableWithURL } = useTables();
    const [ freeze, setFreeze ] = useState();
    const [ searchStarted, setSearchStarted ] = useState();
    const [ currentLayout, setCurrentLayout ] = useState();
    const keys = useRef();
    const searchTimer = useRef();
    
    useEffect(() => {
        if (!layout) {return};
        setFreeze(true);
        const isPhone = size === 'phone';
        const updatedLayout = { ...layout, isPhone, countryCode: getCountry() };
        setCurrentLayout(updatedLayout);
        if (!noURL) {updateTableWithURL(id, updatedLayout)};
    }, []);

    useEffect(()=>{
        if (!data || !layout) {return}
        setFreeze(false);
        const isPhone = size === 'phone';
        keys.current = getKeys(layout, isPhone);
        const updatedLayout = { ...layout, isPhone, countryCode: getCountry()};
        const newData = runFilters(updatedLayout, data);
        setCurrentLayout(updatedLayout);
        updateTable('data', id, newData, noURL);
    }, [data, layout])

    useEffect(() => {
        searchTimer.current = setTimeout(()=>{ 
            setFreeze(true);
            setSearchStarted(true);
        }, 200)
        return () => {clearTimeout(searchTimer.current)};
    }, [tableSearches?.[id], tableSorts?.[id], tableDropdowns?.[id]])

    useEffect(() => {
        if (!searchStarted || !data) return;
        updateTable('data', id, runFilters(currentLayout, data), noURL);
        setFreeze(false);
        setSearchStarted(false);
    }, [searchStarted, data]);

    const runFilters = (layout, data) => {
        let info = [...data];
        const dropdowns = tableDropdowns?.[id];
        const defaultSort = layout?.defaultSort;
        if (dropdowns) { info = filterByDropdowns(info, dropdowns)};
        const input = tableSearches?.[id] ?? '';
        if (input && info.length > 0 ) {info = filterByInput(info, input, layout, keys.current)}
        const sort = tableSorts?.[id] ?? defaultSort;
        const orderBy = sort?.orderBy ?? layout?.defaultSort?.orderBy; 
        if (orderBy) {
            info = [...sortList(info, orderBy, sort.desc)];
        }

        const subtotals = layout?.subtotals;
        if (subtotals) {
            updateTable('totals', id, getSubtotalContent(keys.current, subtotals, info, size), noURL);
        }
        const groupBy = subtotals?.groupBy;
        if (
            groupBy && 
            groupBy !== 'all' &&
            !tableSorts?.[id] && 
            orderBy === groupBy && 
            info.length >= 1 && 
            getUniqueCount(info, groupBy) > 1
        ) {
            return insertSubtotalRows(keys.current, info, subtotals, groupBy);
        }
        return info;
    }
    
    const filterByDropdowns = (info, dropdowns) => {
        if (!dropdowns) return info;
        return Object.entries(dropdowns).reduce((filteredInfo, [prop, dropdown]) => {
            if (dropdown.value === null || !dropdown.searchData) return filteredInfo;
    
            const dropdownValue = dropdown[dropdown.searchData.dropdownProp]?.toString();
            const recordProp = dropdown.searchData.recordProp;
    
            return filteredInfo.filter(record => record[recordProp]?.toString() === dropdownValue);
        }, info);
    };
    

    const getUniqueCount = (items, propName) => {
        const uniqueValues = new Set();
        for (const item of items) {
            if (item[propName] !== undefined) {
                uniqueValues.add(item[propName]);
            }
        }
        return uniqueValues.size;
    };

    const insertSubtotalRows = (columns, rows, subtotals, groupBy) => {
        let current = rows[0][groupBy];
        let newRows = []
        let matchingSet = [];
        let nullSet = []
        rows.forEach((row) => {
            const val = row[groupBy];
            if (val === current) {
                !current ? nullSet.push(row) : matchingSet.push(row);
            } else {
                if (current && matchingSet.length === 1) {
                    newRows.push(matchingSet[0]);
                } else if (current) {
                    newRows.push({ subtitle: getSubtitleContent(columns, subtotals, current, currentLayout)});
                    newRows.push.apply(newRows, matchingSet);
                    newRows.push({ subtotals: getSubtotalContent(columns, subtotals, matchingSet, size)});
                }
                matchingSet = [row];
                current = val;
            }
        });

        if (matchingSet.length > 1) {
            newRows.push({ subtitle: getSubtitleContent(columns, subtotals, current, currentLayout)});
            newRows.push.apply(newRows, matchingSet);
            newRows.push({ subtotals: getSubtotalContent(columns, subtotals, matchingSet, size)});
        } else if (matchingSet.length === 1) {
            newRows.push(matchingSet[0]);
        }  
        if (nullSet.length === 1) {
            newRows.push(nullSet[0]);
        } else if (nullSet.length > 1) {
            newRows.push({ subtitle: getSubtitleContent(columns, subtotals, null, layout)});
            newRows.push.apply(newRows, nullSet);
            newRows.push({ subtotals: getSubtotalContent(columns, subtotals, nullSet, size)});
        }
        return newRows;
    }

    return (
        <div id={id} className={`table ${classes ? classes : ''} ${(freeze || masked)?  'freeze' : ''} `} >
            <TableSearchBar id={id} layout={currentLayout} searchBarContent={searchBarContent} masked={masked} noURL={noURL}/>
            <div id={id + '-container'} className={`table-container`}>
                <TableHeaders id={id} layout={currentLayout} noURL={noURL}/>
                <TableBody id={id} layout={currentLayout} masked={freeze || masked} noURL={noURL} callback={callback} />
                <TableTotals id={id} layout={currentLayout} resultsValues={resultsValues} columns={keys.current} classes={'listed'} loading={freeze || masked} noURL={noURL}/>
            </div>
        </div>
    )
}

export default TableListed;
