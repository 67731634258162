import { text as t } from 'shared/text';

export const deviceControlsLayout = {
    id: 'device-controls',

    headers: {
        deviceCommands: t.deviceCommands,
        deviceLogs: t.deviceLogs
    },

    tableHeaders: {
        id: t.id,
        createdOn: t.createdOn,
        download: t.downloadLog
    },

    sizes: {
        id: 'small',
        createdOn: 'large',
        download: 'mid'
    },
    
    formats: {
        createdOn: 'date-standard'
    },

    hideSortArrows: {
        download: true
    },

    navigation: {
        download: {
            labelStatic: t.download,
            onClick: true,
        }
    }
}
