import { text as t } from 'shared/text';

export const pullTabsLayout = {
    id: 'pulltabs',

    hasDateRange: true,

    defaultSort:  {
        orderBy: 'id',
        desc: true,
    },

    tableHeaders: {
        id: t.batchId,
        vendor: t.vendor,
        createdOn: t.createdOn,
        resendEmail: t.resendEmail
    },

    sizes: {
        id: 'small',
        vendor: 'large',
        createdOn: 'large',
        resendEmail: 'small'
    },

    layouts: {
        id: 'text-left',
        vendor: 'text-left',
        createdOn: 'text-left',
    },

    formats: {
        createdOn: 'date-standard'
    },

    navigation: {
        resendEmail: {
            labelStatic: t.send,
            onClick: true,
        }
    }
}
