import './companyPerformanceReports.scss';
import { useEffect, useRef, useState } from 'react';
import { companyPerformanceReportsLayout as layout } from './CompanyPerformanceReportsLayout';
import { getDefaultDateRange } from 'components/shared/componentUtils';
import { reportsData } from 'shared/data';
import { text as t } from 'shared/text';
import * as e from 'shared/excel/excel';
import useInputs from 'components/hooks/Inputs/useInputs';
import useProfile from 'hooks/Profile/useProfile';
import useTables from 'components/Tables/TableParts/TableHooks/useTables';
import useUtils from 'hooks/useUtils';
import Button from 'components/Button/Button';
import DateRangePicker from 'components/DateRangePicker/DateRangePicker';
import Dropdown from 'components/Dropdown/Dropdown';
import TablePaged from 'components/Tables/TablePaged';
const tableId = 'companyPerformanceReports';
const companyId = 'CompanyId';
const terminalTypeId = 'TerminalTypeId' // TODO: add in if requested

const CompanyPerformanceReports = () => {
    const { inputValues, dropdownOriginals, updateDropdown } = useInputs();
    const { freeze, setFreeze } = useProfile();
    const { tableDropdowns, getURLParams, 
            updateDropdowns, updateTable, 
            configureExcelDates, configureExcelReport } = useTables();
    const { callAPI, callAPIGet } = useUtils();
    const [ companies, setCompanies ] = useState();
    const [ defaultDates, setDefaultDates ] = useState();
    const [ currentRange, setCurrentRange ] = useState();
    const runOnce = useRef(false);

    useEffect(() => {
        if (runOnce.current) return;
        runOnce.current = true;
        const range = getDefaultDateRange(tableId);
        setDefaultDates(range);
        setCurrentRange(range);
        callAPI('companies', companiesCallback);
    }, []);

    useEffect(() => {
        if (inputValues?.[reportsData.dropdownId] && companies){
            const urlParams = getURLParams(tableId);
            if (urlParams.dropdowns) {
                const sorts = urlParams.dropdowns?.[companyId];
                sorts && updateDropdown(companyId, sorts.value, sorts.label);
            }
        }
    }, [inputValues?.[reportsData.dropdownId], companies])
    
    const getReportsData = (data, callback) => {
        setFreeze(true);
        const params = {
            Search: data.searchTerm,
            PageIndex: data.currentPage,
            PageSize: data.rowCount,
            OrderBy: data.orderBy,
            Desc: data?.desc,
        }
        const dropdowns = tableDropdowns?.[tableId];
        if (dropdowns) {
            Object.keys(dropdowns).forEach((key) => {
                params[key] = dropdowns[key].value;
            });
        }
        if (data.periodStart) {
            params.PeriodStart = data.periodStart;
            params.PeriodEnd = data.periodEnd;
        }
        data.callback = callback;
        callAPIGet('report-CompanyPerformance', companyPerformanceCallback, params, data);
    }

    const companyPerformanceCallback = (data, props) => {
        setFreeze(false);
        if (data.data && props.callback) {
            props.records = data.data;
            props.pageCount = data.pageCount ? data.pageCount : '';
            props.callback(props);
        }
    }

    const companiesCallback = (data) =>{
        setCompanies(data?.companyList ? data.companyList : []);
    }

    const onCompanySelect = (data) => {
        data.searchData = {
            dropdownProp: 'label',
            recordProp: 'companyName',
        }
        updateDropdowns(tableId, companyId, data);
    }

    const onDateRangePick = (range) => {
        updateTable('selectedRows', tableId, null);
        setCurrentRange(range);
        setTimeout(() => {
            updateTable('dates', tableId, range);
        }, 0)
    }

    const onExportToExcel = () => { 
        const title = `${t.companyPerformance} ${t.report}`
        const dateInfo = configureExcelDates(tableId, currentRange, {
            title: title,
            columns: ['companyName', 'startDate', 'endDate'],
            data:[{companyName: dropdownOriginals?.[companyId] || t.all}],
            configs: {
                formats: {companyName: 'text-wrap'}
            }
        });
        layout.formats.companyName = 'text-wrap';
        const [tableInfo, sizes] = configureExcelReport(tableId, t.companies, layout);
        e.exportToFile(title, [dateInfo, tableInfo], sizes);
    }

    return (<TablePaged
        id={tableId}
        layout={layout}
        defaultDates={defaultDates}
        apiId='report-GamePerformance'
        apiCall={getReportsData}
        searchBarContent={<>
            <Dropdown
                id={companyId}
                classes='quarter'
                label={t.companyName}
                data={companies}
                masked={freeze}
                nullable={t.all}
                hideErrors={true}
                callback={onCompanySelect}
            />
            <DateRangePicker
                id='companyReport-dateRange'
                classes='third'
                defaultVal={defaultDates}
                label={t.dateRange}
                stateId={tableId}
                disabled={freeze}
                callback={onDateRangePick}/>
            <Button
                classes='green sixth match-labeled-input excel-export'
                disabled={freeze}
                onClick={onExportToExcel}
            >{t.exportToExcel}</Button>
        </>}
    />)
}

export default CompanyPerformanceReports;
