import { text as t } from 'shared/text'

export const kioskPerformanceReportLayout = {  
    defaultSort: {
        orderBy: 'profit',
        desc: true,
    },

    hasDateRange: true,

    tableHeaders: {
        companyName: t.companyName,
        locationName: t.locationName,
        stateCode: t.state,
        cashIn: t.cashIn,
        cashOut: t.cashOut,
        profit: t.profit
    },

    sizes: {
        companyName: 'large',
        locationName: 'large',
        stateCode: 'small',
        cashIn: 'mid',
        cashOut: 'mid',
        profit: 'xl',
    },

    layouts: {
        companyName: 'text-left',
        locationName: 'text-left',
        stateCode: 'text-left phone',
        cashIn: 'text-right phone',
        cashOut: 'text-right phone',
        profit: 'text-right',
    },

    formats: {
        cashIn: 'currency',
        cashOut: 'currency',
        profit: 'currency',
    },

    navigation: {
        companyName: {
            label: 'companyName',
            data:  [
                {type: 'text', val: 'company'},
                {type: 'prop', val: 'companyId'}
            ]
        },
        locationName: {
            label: 'locationName',
            data:  [
                {type: 'text', val: 'location'},
                {type: 'prop', val: 'locationId'}
            ]
        }
    },
    subtotals: {
        groupBy: 'companyName',
        fields: {
            companyName: 'header',
            cashIn: 'total',
            cashOut: 'total',
            promo:'total',
            profit:'total',
        },
        formats: {
            cashIn: 'currency',
            cashOut: 'currency',
            promo:'currency',
            profit:'currency',
        }
    }
}
