import './manifestDetails.scss';
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { deepCopy } from 'components/shared/componentUtils';
import { text as t } from 'shared/text';
import useComponents from 'components/hooks/Components/useComponents';
import useInputs from 'components/hooks/Inputs/useInputs';
import useModal from 'components/hooks/Modal/useModal';
import useProfile from 'hooks/Profile/useProfile';
import useUtils from 'hooks/useUtils';
import Button from 'components/Button/Button';
import Card from 'app-components/Cards/Card/Card';
import Checkbox from 'components/Checkbox/Checkbox';
import DateTimePicker from 'app-components/DateTimePicker/DateTimePicker';
import Dropdown from 'components/Dropdown/Dropdown';
import Input from 'components/Input/Input';
import Page from 'app-components/Page/Page';
import ConfirmModal from 'modals/FlowModals/ConfirmModal/ConfirmModal';

const ManifestDetails = ({ newManifest, newSubmit, newCancel }) => {
    const { id } = useParams();
    const { inputValues, changeInputValues, updateInputData } = useInputs();
    const { setHeader } = useProfile();
    const { callAPI, updateNotice } = useUtils();
    const { addModal } = useModal();
    const { navTo } = useComponents();
    const [ pageData, setPageData ] = useState();
    const [ companies, setCompanies ] = useState();
    const [ devices, setDevices ] = useState();
    const [ locations, setLocations ] = useState();
    const [ sources, setSources ] = useState();
    const [ users, setUsers ] = useState();
    const [ deviceTypes, setDeviceTypes ] = useState();
    const [ deviceSubtypes, setDeviceSubtypes ] = useState();
    const [ currentDeviceType, setCurrentDeviceType ] = useState();
    const [ masked, setMasked ] = useState(true);
    const [ subtypeMask, setSubtypeMask ] = useState(false);
    const runOnce = useRef(false);
    const updatedOnce = useRef(false);

    const dropdownLists = {
        updateSourceId: { list: 0 },
        userId: { list: 1, nullable: t.noUser },
        companyId: { list: 2, nullable: t.noCompany },
        locationId: { list: 3, nullable: t.noLocation },
        terminalId: { list: 4, nullable: t.noDevice },
        terminalTypeId: { list: 5, nullable: t.noDeviceType },
        terminalSubClassId: { list: 6, nullable: t.noDeviceSubtype, valueProp: 'terminalSubClassId', labelProp: 'description' },
    };

    useEffect(() => {
        if (runOnce.current) {return;}
        runOnce.current = true;
        const params = newManifest ? { updateManifestId: -1 } : { updateManifestId: id };
        callAPI('manifestDetails', manifestDetailsCallback, params);
        callAPI('sources', sourcesCallback, { id: null });
        callAPI('users', usersCallback);
        callAPI('companies', companiesCallback);
        callAPI('devices', terminalsCallback);
        callAPI('deviceTypes', deviceTypesCallback, params);
        callAPI('locationsByCompany', locationsByCompanyCallback, { validAddress: true });
    }, []);

    useEffect(() => {
        if (!updatedOnce.current && pageData && sources && users && locations && companies && devices && deviceTypes && deviceSubtypes) {
            updatedOnce.current = true;
            const updated = updateInputData(pageData, dropdownLists, [sources, users, companies, locations, devices, deviceTypes, deviceSubtypes]);
            updated && setMasked(false);
        }
    }, [pageData, sources, users, locations, companies, devices, deviceTypes, deviceSubtypes]);

    const manifestDetailsCallback = (data) => {
        const details = data?.tableData?.[ 0 ];
        setPageData(details ? details : []);
        const typeId = details?.terminalTypeId;
        setCurrentDeviceType(typeId);
        typeId ? callAPI('deviceSubtypes', deviceSubtypesCallback, { terminalTypeId: typeId }) : setDeviceSubtypes([]);
        !newManifest && setHeader(t.device + ': ' + (details?.terminalId ? details.terminalId : t.nullVal));
    };

    const companiesCallback = (data) => {
        if (!data?.isSuccessful) {return;}
        const list = data.companyList;
        setCompanies(list);
    };

    const locationsByCompanyCallback = (data) => {
        if (!data?.isSuccessful) {return;}
        const list = data.tableData;
        setLocations(list);
    };

    const sourcesCallback = (data) => {
        if (!data?.isSuccessful) {return;}
        const list = data.tableData;
        setSources(list);
    };

    const usersCallback = (data) => {
        if (!data?.isSuccessful) {return;}
        const list = data.webPortalUserList;
        setUsers(list);
    };

    const terminalsCallback = (data) => {
        if (!data?.isSuccessful) {return;}
        const list = data?.list;
        setDevices(list);
    };

    const deviceTypesCallback = (data) => {
        if (!data?.isSuccessful) {return;}
        const list = data?.kioskTypes;
        setDeviceTypes(list);
    };

    const deviceSubtypesCallback = (data, props) => {
        const subtypeId = 'terminalSubClassId';
        const list = data?.isSuccessful ? data.subClassList : [];
        setDeviceSubtypes(list);
        setSubtypeMask(false);
        if (props?.selecting) {
            const newData = deepCopy(inputValues);
            newData.terminalTypeId = props.value;
            newData[ subtypeId ] = null;
            updateInputData(newData, dropdownLists, [sources, users, companies, locations, devices, deviceTypes, list]);
        } else if (props?.adding) {
            updateNotice(data, { id: 'terminalSubClassId' });
        }
    };

    const onDateTimePick = (date) => {
        if (!pageData) {return;}
        const newStates = deepCopy(inputValues);
        newStates.targetDateTime = date;
        changeInputValues(newStates);
    };

    const onDeviceTypeSelect = (data) => {
        setSubtypeMask(true);
        const typeId = data.value;
        setCurrentDeviceType(typeId);
        data.selecting = true;
        callAPI('deviceSubtypes', deviceSubtypesCallback, { terminalTypeId: typeId }, data);
    };

    const onDeviceSubtypeAdd = (text) => {
        setSubtypeMask(true);
        const params = {
            description: text,
            terminalTypeId: currentDeviceType,
        };
        callAPI('deviceSubtypeAdd', deviceSubtypeAddCallback, params);
    };

    const deviceSubtypeAddCallback = (data) => {
        if (data?.isSuccessful) {
            callAPI('deviceSubtypes', deviceSubtypesCallback, { terminalTypeId: currentDeviceType }, { adding: true });
        } else {
            updateNotice(data);
        }
    };

    const onSubmit = (event, setLoading) => {
        const props = { event: event, setLoading: setLoading };
        setLoading(true);
        const params = { record: inputValues };
        callAPI('manifestUpdate', manifestUpdateCallback, params, props);
    };

    const manifestUpdateCallback = (data, props) => {
        newManifest ? newSubmit(data) : updateNotice(data, props);
    };


    const onCancel = () => {
        newCancel();
    };

    const onDelete = (event, setLoading) => {
        const props = { event: event, setLoading: setLoading };
        addModal(<ConfirmModal 
            header={t.warning} 
            onConfirm={() => {
                setLoading(true);
                callAPI('manifestDelete', manifestDeleteCallback, {manifestId: Number(id)}, props)
            }}
            content={t.warningForDeleteManifest}
        />);
    };

    const manifestDeleteCallback = (data, props) => {
        props.setLoading(false);
        if (data?.isSuccessful) {
            updateNotice(data);
            navTo('/manifests',['replace'] )
        } else {
            updateNotice(data, props);
        }
    };

    return (
      <Page classes='manifest-details' contentClasses='grid'>
          <Card classes='full' hideLabel={true}>
              <Dropdown id='updateSourceId' classes='quarter' label={t.updateSource} data={sources} masked={masked} />
              <Input id='updateSourceType' classes='sixth display-only' disabled={true} label={t.sourceType} masked={masked} />
              <Input id='targetVersion' classes='sixth' label={t.version} masked={masked} />
              <DateTimePicker classes='quarter phone' defaultVal={pageData?.targetDateTime} label={t.targetDateTime}
                              callback={(date) => {onDateTimePick(date);}} />
              <Checkbox id='isActive' classes='sixth' label={t.isActive} />

              <Dropdown id='userId' classes='quarter' nullable={t.noUser} label={t.user} data={users} masked={masked} />
              <Dropdown id='companyId' classes='quarter' nullable={t.noCompany} label={t.company} data={companies} masked={masked} />
              <Dropdown id='locationId' classes='quarter' label={t.location} data={locations} masked={masked} />
              <Dropdown id='terminalId' classes='quarter' nullable={t.noDevice} label={t.terminalId} data={devices} masked={masked} />

              <Dropdown id='terminalTypeId' classes='quarter' nullable={t.noDeviceType} data={deviceTypes} updatesNext={true}
                        label={t.terminalType} masked={masked} callback={onDeviceTypeSelect} />
              <Dropdown id='terminalSubClassId' classes='quarter' nullable={t.noDeviceSubtype} data={deviceSubtypes}
                        disabled={!currentDeviceType}
                        label={t.terminalSubtype} masked={masked || subtypeMask} valueProp='terminalSubClassId' labelProp='description'
                        addCallback={onDeviceSubtypeAdd} />
              <Checkbox id='isNewInstall' classes='quarter phone' label={t.isNewInstall} />
          </Card>
          {newManifest && <div className='full modal-buttons-container grid'>
            <Button
                classes='red quarter'
                onClick={onCancel}
                >{t.cancel}
            </Button>
            <Button
                classes='green quarter last phone'
                type='submit'
                onClick={(event, setLoading) => onSubmit(event, setLoading)}
                >{t.submit}
            </Button>
          </div>}
          {!newManifest &&<div className='grid half last margin-details-buttons'> 
            <Button
                classes='red half phone'
                type='submit'
                onClick={(event, setLoading) => onDelete(event, setLoading)}
                >{t.delete}
            </Button>
            <Button
                classes='green half last phone'
                type='submit'
                onClick={(event, setLoading) => onSubmit(event, setLoading)}
                >{t.submit}
            </Button>
        </div>}
      </Page>
    );
};

export default ManifestDetails;
