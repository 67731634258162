import './locationServices.scss';
import { useParams } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { text as t } from 'shared/text';
import useInputs from 'components/hooks/Inputs/useInputs';
import useProfile from 'hooks/Profile/useProfile';
import useUtils from 'hooks/useUtils';
import Button from 'components/Button/Button';
import Card from 'app-components/Cards/Card/Card';
import Page from 'app-components/Page/Page';
import ServiceItem from 'app-components/ServiceItem/ServiceItem';

const LocationServices = () => {
    const { id }  = useParams();
    const { inputValues, changeInputErrors, 
            reNestedData, updateNestedInputData,
            clearAllInputs } = useInputs();
    const { size, locationTabs, 
            updateLocationBreadcrumbs,
            updateLocationHeader } = useProfile();
    const { callAPI, callAPIGet, 
            hasRank, updateNotice,
            updateLocationTabs } = useUtils();
    const [ pageData, setPageData ] = useState();
    const [ services, setServices ] = useState();
    const [ serviceDisplays, setServiceDisplays ] = useState();
    const [ masked, setMasked ] = useState(true);    const runOnce = useRef(false);
    const setOnce = useRef(false);
    const deviceTypes = useRef(false);

    useEffect(() => {
        if (runOnce.current && pageData?.id === id) {return};
        runOnce.current = true;
        callAPI('locationDetails', locationDetailsCallback, {id: id});
        callAPIGet('locationServicesGet', locationServicesGetCallback, {locationId: id});
        return () => {clearAllInputs()};
    }, []);

    useEffect(() => {
        changeInputErrors(null);
    }, [inputValues]);

    useEffect(() => {
        if (services && deviceTypes.current && !setOnce.current) {
            setOnce.current = true;
            const newServices = {};
            let found;
            deviceTypes.current.forEach((service) => {
                const type = getLocationServiceType(service);
                if (type) {
                    found = true;
                    newServices[type] = services[type];
                    newServices[type].present = true;
                    newServices[type].label = getServiceLabel(type);
                }
            })
            setServiceDisplays(found ? newServices : null);
            const update = updateNestedInputData(newServices);
            update && setMasked(false);
        }
    }, [services, deviceTypes.current])

    const locationDetailsCallback = (data) => {
        if (data?.isSuccessful) {
            const details = data?.locationDetails;
            updateLocationHeader(details);
            updateLocationBreadcrumbs(details);
            const types = data?.kioskTypes;
            deviceTypes.current = types;
            updateLocationTabs(types);
            updateLocationTabs
            setPageData(details);
        } else {
            setPageData({})
        }
        setMasked(false)
    }

    const locationServicesGetCallback = (data) => {
        if (data.isSuccessful) {
            delete data.errorMessage;
            delete data.httpStatusCode;
            delete data.isSuccessful;
            delete data.responseTypeEnum;
            setServices(data);
            updateNestedInputData(data);
        }
    }

    const onSubmit = (event, setLoading) => {
        const errorsList = validateRates();
        const props = {event: event, setLoading: setLoading}
        if (errorsList) {
            updateNotice('formErrors', props);
            changeInputErrors(errorsList);
        } else {
            setLoading(true);
            changeInputErrors({})
            const params = reNestedData(services);
            params.locationId = id;
            callAPI('locationServicesSet', locationServicesSetCallback, params, props);
        }
    }

    const locationServicesSetCallback = (data, props) => {
        props.setLoading(false);
        updateNotice(data, props);
    }

    // Helper Functions
    const validateRates = () => {
        let errors;
        Object.keys(services).forEach((prop) => {
            const opId = prop + '-operatorRate';
            const locId = prop + '-locationRate';
            if (prop !== 'primePay' && inputValues[prop + '-active'] && (parseInt(inputValues?.[opId]) + parseInt(inputValues?.[locId]) !== 100)) {
                if (!errors) { errors = {} };
                errors[opId] = t.errorShareRates;
                errors[locId] = t.errorShareRates;
            }
        })
        return errors;
    }

    // Ties to getServicesTypes in CompanyServices.js
    const getLocationServiceType = (val) => {
        switch (val) {
            case 1: 
                return 'primePay'
            case 2:
            case 4:
                return 'sweepsKiosk'
            case 3:
            case 6: 
                return 'connectedGames'
            case 7:
                return 'bingoKiosk'
            default:
                return null;
        }
    }

    const getServiceLabel = (val) => {
        switch (val) {
            case 'primePay':
                return 'PrimePay';
            case 'sweepsKiosk':
                return 'SweepsKiosk';
            case 'bingoKiosk':
                return 'BingoKiosk';
            case 'connectedGames':
                return 'ConnectedGames';
            default:
                return t.unknown
        }
    }

    return (
        <Page
            subPage={t.services}
            tabs={locationTabs.tabs}
            contentClasses='grid location-details'
        >
            <Card classes={`full`} label={t.services}>
                {serviceDisplays ? Object.entries(serviceDisplays).map(([prop], i) => (
                    <ServiceItem
                        key={i}
                        prop={prop}
                        page={'location'}
                        classes='third'
                        masked={masked}
                    />
                )): <div className='no-services-message full'>{t.noResults}</div>}
                {(hasRank('owner') && size !== 'phone') && <div className='grid full'>
                    <Button
                        classes={`green last quarter`}
                        type='submit'
                        onClick={(event, setLoading)=>onSubmit(event, setLoading)}
                        >{t.submit}
                    </Button>
                </div>}
            </Card>

        </Page>
    )
}

export default LocationServices;
