export const text = {
    actions: 'Actions',
    activations: 'Activations',
    active: 'Active',
    addCompany: 'Add Company',
    addLocation: 'Add Location',
    addManifest: 'Add Manifest',
    addSource: 'Add Source',
    addUser: 'Add User',
    addUserToCompany: 'Add User to Company',
    address: 'Address',
    addressTwo: 'Apt, Suite Number (Optional)',
    adminOnly: 'Admin Only',
    adminPinChange: 'Change Admin PIN',
    adminPinCreate: 'Create Admin PIN',
    adminPinManage: 'Manage Admin PIN',
    administrator: 'Administrator',
    alertContacts: 'Alert Contacts',
    alertEmail: 'Email Alert',
    alertForTen: '$10.00 count',
    alertForTenUGD: '10.00 count',
    alertForTwenty: '$20.00 count',
    alertForTwentyUGD: '20.00 count',
    alertText: 'Text Alert',
    all: 'All',
    anyDeskNumber: 'Any Desk Number',
    apiLogs: 'API Logs',
    assign: 'Assign',
    applyToRole: 'Apply Permissions to the Role',
    assignedUser: 'Assigned User',
    assignment: 'Assignment',
    assignmentNotice: 'It may take a minute for the system to fully reassign this device. You can close this modal, but please remain on the page until the Assign button returns and you see notice "Update Complete".',
    assignmentInProgress: 'Assignment In Progress',
    audit: 'Audit',
    authenticated: 'Authenticated',
    averageBet: 'Avg Bet',
    averageWin: 'Avg Win',
    awards: 'Awards',
    back: 'Back',
    balanceCashable: 'Cashable Balance',
    balanceHistory: 'Balance History',
    balanceNonCashable: 'Non-Cashable Balance',
    balances: 'Balances',
    batchAssignment: 'Batch Assignment',
    batchId: 'Batch Id',
    betLevel: 'Bet Level',
    billAcceptorOperational: 'Bill Acceptor Operational',
    billCount: 'Bill Count',
    billingContact: 'Billing Contact',
    billingInfo: 'Billing Information',
    bingo: 'Bingo',
    bingoKiosk: 'Bingo Kiosk',
    boardSerial: 'Board #',
    bonusFreespins: 'Free',
    boxNumber: 'Box #',
    cabinet: 'Cabinet',
    cabinetSerial: 'Cabinet #',
    callerId: 'Caller ID',
    cancel: 'Cancel',
    cashBreakdown: 'Cash Breakdown',
    cashIn: 'Cash In',
    cashOnHand: 'Cash On Hand',
    cashOut: 'Cash Out',
    cassette: 'Cassette',
    cassettes: 'Cassettes',
    changeAdminPinWarning: 'This will change the admin PIN for ALL GRC Kiosks under this user account. Are you sure you want to continue?',
    chooseCSV: 'Choose CSV',
    city: 'City',
    close: 'Close',
    collections: 'Collections',
    commissioned: 'Commissioned',
    companies: 'Companies',
    company: 'Company',
    companyId: 'Company ID',
    companyInfo: 'Company Information',
    companyName: 'Company Name',
    companyPerformance: 'Company Performance',
    configureSettingsOptional: 'Congigure Settings (optional)',
    confirm: 'Confirm',
    confirmLogout: 'Are you sure you want to log out?',
    confirmNewPassword: 'Confirm New Password',
    confirmPassword: 'Confirm Password',
    connectedGames: 'Connected Games',
    contactName: 'Contact Name',
    controls: 'Controls',
    copied: 'Copied',
    count: 'Count',
    country: 'Country',
    county: 'County',
    create: 'Create',
    createdOn: 'Created On',
    credentialsExpired: 'Credentials Expired. Please Log Back In.',
    currentCompany: 'Current Company',
    currentJackpots: 'Current Jackpots',
    currentLocation: 'Current Location',
    currentLocations: 'Current Locations',
    darkMode: 'Dark Mode',
    dashboards: 'Dashboards',
    dateRange: 'Date Range',
    day: 'Day',
    delete: 'Delete',
    deleted: 'Deleted',
    deleteAccounts: 'Delete Accounts',
    deleteAccountsWarning: "You are about to delete a player's account(s). This cannot be reversed. Are you sure you want to proceed?",
    denomination: 'Denomination',
    deposits: 'Deposits',
    deselect: 'Deselect',
    details: 'Details',
    detailType: 'Detail Type',
    device: 'Device',
    deviceId: 'Device ID',
    deviceType: 'Device Type',
    deviceCommands: 'Device Commands',
    deviceInfo: 'Device Info',
    deviceLogs: 'Device Logs',
    devices: 'Devices',
    destination: 'Destination',
    download: 'Download',
    downloadLog: 'Download Log',
    duplicateCheckbox: 'Same as Company Info',
    edit: 'Edit',
    email: 'Email',
    emailInvalid: 'Email invalid. Please try again.',
    emailIsAlreadySent: 'Email has been sent.',
    emailTaken: 'Email already in use',
    emailUnchecked: "Email couldn't be checked",
    enabled: 'Enabled',
    encryptAndDownload: 'Encrypt & Download',
    encryptAndEmail: 'Encrypt & Email',
    enterPassword: 'Enter Password',
    enterPhoneNumber: 'Enter Phone Number',
    environment: 'Environment',
    end: 'End',
    endDate: 'End Date',
    entries: 'Entries',
    entriesNoCap: 'entries',
    error: 'Error',
    errorEmail: 'Please enter a valid email',
    errorInvalidCSV: 'Please select a valid CSV file',
    errorManifestsOnSource: 'All manifests must be removed from this source before it can be deleted.',
    errorNoEndpoint: 'Requested endpoint not found',
    errorPhone: 'Please enter a valid phone number',
    errorRequired: 'Required',
    errorPasswordReset: 'Unable to reset password. Please try again.',
    errorShareRates: 'Operator and Location Shares must add up to 100',
    errorZip: 'Please put in a valid zip code',
    errGeneral: 'Whoops! Looks like something went wrong.',
    executeScript: 'Execute Script',
    executeScriptConfirmMessage: 'Enter command. Do not enter ‘sudo’ as this will be done by the device',
    exportToExcel: 'Export to Excel',
    fail: 'Fail',
    false: 'False',
    fileName: 'File Name',
    financials: 'Financials',
    firmwareVersion: 'Firmware Version',
    first: 'First',
    firstName: 'First Name',
    fullName: 'Full Name',
    forgotPassword: 'Forgot Password',
    forgotPasswordInstructions: 'Enter your email below to reset your password.',
    formErrors: 'Form Errors',
    game: 'Game',
    gameDetails: 'Game Details',
    gameroom: 'Gameroom',
    gamerooms: 'Gamerooms',
    gameroomPerformance: 'Gameroom Performance',
    gameroomPOS: 'Gameroom POS',
    games: 'Games',
    gamePerformance: 'Game Performance',
    generateLicenseAndDownload: 'Generate License & Download',
    generateLicensesAndDownload: 'Generate License(s) & Download',
    goldRushCityKiosk: 'Gold Rush City Kiosk',
    grand: 'Grand',
    grc: 'GRC',
    grcKiosks: 'GRC Kiosks',
    grcPOS: 'GRC POS',
    headers: 'Headers',
    highestWin: 'Top Win',
    hitFrequencyPercentage: 'Hit Freq %',
    hold: 'Hold',
    holdPercentage: 'Hold %',
    id: 'ID',
    importedOn: 'Imported On',
    includedLocations: 'Included Locations',
    inputSearch: 'Search',
    installDate: 'Install Date',
    inService: 'In Service',
    inventory: 'Inventory',
    ipAddress: 'IP Address',
    isActive: 'Is Active',
    isNewInstall: 'For New Installs',
    jackpotType: 'Jackpot Type',
    jackpots: 'Jackpots',
    kiosk: 'Kiosk',
    kioskHardwareAlerts: 'Kiosk Hardware Alerts',
    kioskLoginQR: 'Kiosk Login QR',
    kioskLowBillAlerts: 'Kiosk Low Bill Alerts',
    kioskPerformance: 'Kiosk Performance',
    kiosks: 'Kiosks',
    last: 'Last',
    lastCaptureFromKiosk: 'Last Capture from Kiosk',
    lastMonth: 'Last Month',
    lastName: 'Last Name',
    lastOnline: 'Last seen online',
    lastQuarter: 'Last Quarter',
    lastWeek: 'Last Week',
    licenseRate: 'License Rate',
    locationManager: 'Location Manager',
    location: 'Location',
    locationPerformance: 'Location Performance',
    locationRate: 'Location Share',
    locationId: 'Location ID',
    locationName: 'Location Name',
    locationType: 'Location Type',
    locations: 'Locations',
    loggingOut: 'Logging Out',
    loginFailed: 'Login invalid. Please try again.',
    logout: 'Logout',
    logs: 'Logs',
    logClass: 'Log Class',
    major: 'Major',
    manifests: 'Manifests',
    manualCollect: 'Manually Collected',
    mac: 'MAC address',
    message: 'Message',
    messages: 'Messages',
    mini: 'Mini',
    minor: 'Minor',
    monitor: 'Monitor',
    monitors: 'Monitors',
    monthToDate: 'Month to Date',
    na: 'N/A',
    name: 'Name',
    nameAlreadyTaken: 'Name already taken',
    needGRCKiosk: "Available once the user's company has a GRC Kiosk",
    netCash: 'Net Cash',
    netLiability: 'Net Liability',
    netRevenue: 'Net Revenue',
    newLocations: 'New Locations',
    newPassword: 'New Password',
    newPlayers: 'New Players',
    next: 'Next',
    none: 'None',
    noCompany: 'No Company',
    noDevice: 'No Device',
    noDeviceSubtype: 'No Device Subtype',
    noDeviceType: 'No Device Type',
    noFeaturesAvailable: 'No Features Available',
    noFileSelected: 'No File Selected',
    noLocation: 'No Location',
    noPurchaseNecessary: 'No Purchase Necessary',
    noResults: '- no results -',
    noRoleAssigned: '- no role assigned -',
    noUser: 'No User',
    noUserData: 'No data available for this user',
    notSet: 'Not Set',
    nullVal: '-',
    online: 'Online',
    onlineNow: 'Online as of',
    operatorRate: 'Operator Share',
    openSupportTunnel: 'Open Support Tunnel',
    openSupportTunnelConfirmMessage: 'This will open a reverse SSH tunnel to the device.  Use the following command: ‘ssh -i "devmag2vrd.pem" ubuntu@tunnel.trueroute.com’',
    outstanding: 'Outstanding',
    owner: 'Owner',
    page: 'Page',
    pageNotFound: '404: Page Not Found',
    parent: 'Parent',
    password: 'Password',
    passwordConfirmationError: 'Password not equal',
    passwordMinChar: '8 character minimum',
    passwordOneUpper: 'one uppercase',
    passwordOneLower: 'one lowercase',
    passwordOneNum: 'one number',
    passwordOneSpecial: 'one special character',
    passwordInvalicChar: 'invalid special character',
    passwordMismatch: 'passwords match',
    passwordResetInstructions: 'Enter your new password below.',
    pauseService: 'Pause Service',
    pausedService: 'Service Paused',
    pausedManually: 'Manually Paused',
    payRate: 'Pay Rate',
    pass: 'Pass',
    permissions: 'Permissions',
    permissionsOverride: 'You are overriding permissions for:',
    phoneNumber: 'Phone Number',
    piggyBank: 'Piggy Bank Deluxe',
    piggyBankUG: 'Piggy Bank UG',
    playType: 'Play Type',
    player: 'Player',
    playerBalanceHistory: 'Player Balance History',
    playerCredits: 'Player Credits',
    playerInfo: 'Player Info',
    playerPIN: 'Player PIN',
    playerTransactions: 'Player Transactions',
    players: 'Players',
    plays: 'Plays',
    playsAll: 'All Plays',
    playsGameroom: 'Only Gameroom Plays',
    playsMobile: 'Only Mobile Plays',
    pointOfSales: 'Point of Sales',
    pos: 'POS',
    prev: 'Prev.',
    previous: 'Previous',
    primePay: 'PrimePay',
    primePayLabel: 'Prime Pay',
    primePayLowBillAlerts: 'PrimePay Low Bill Alerts',
    principalContact: 'Principal Contact',
    primaryAlert: 'Primary Alert',
    primaryContact: 'Primary Contact',
    profit: 'Profit',
    progress: 'Progress',
    promo: 'Promo',
    promos: 'Promos',
    pullTabs: 'Pull Tabs',
    pullTabsBoxes: 'Pull Tabs Boxes',
    pullTabsVendor: 'Pull Tabs Vendor',
    purchase: 'Purchase',
    quarterToDate: 'Quarter To Date',
    reboot: 'Reboot',
    receipt: 'Receipt',
    recordsNoCap: 'records',
    recycler: 'Recycler',
    recyclers: 'Recyclers',
    redeemed: 'Redeemed',
    redemption: 'Redemption',
    refund: 'Refund',
    refunds: 'Refunds',
    refSerial: 'Reference #',
    releaseEscrow: 'Release Escrow',
    report: 'Report',
    reports: 'Reports',
    resendEmail: 'Resend Email',
    resetPOSAdminPassword: 'Reset POS Admin Password',
    restore: 'Restore',
    results: 'Results',
    revenue: 'Revenue',
    reversal: 'Reversal',
    role: 'Role',
    roles: 'Roles',
    rowCount: 'Row Count',
    rtp: 'RTP',
    salesTaxRate: 'Sales Tax Rate',
    salesAgent: 'Sales Agent',
    save: 'Save',
    search: 'Search',
    secondaryAlert: 'Secondary Alert',
    secondaryContact: 'Secondary Contact',
    securityCheck: 'Security Check',
    select: 'Select',
    selectCompany: 'Select Company',
    selectFeatures: 'Select Features',
    selectLocation: 'Select Location',
    selectOne: 'Select One',
    selectTableColumns: 'Select Table Columns',
    selectVendor: 'Select Vendor',
    selectedReport: 'Selected Report',
    send: 'Send',
    sendCommand: 'Send Command',
    sendLogs: 'Send Logs',
    serialNumber: 'Serial Number',
    serviceEvents: 'Service Events',
    serviceItemAd: 'Interested? Contact Primero to learn more.',
    services: 'Services',
    settings: 'Settings',
    setLocation: 'Set Location',
    setLocationsModalTitle: 'Set location of selected devices:',
    setPassword: 'Set Password',
    show: 'Show',
    showBetRates: 'Show Bet Rates',
    showNoCap: 'show',
    showKioskLoginQRCode: 'Show Kiosk Login QR Code',
    showPin: 'Show PIN',
    showUncommissioned: 'Show Uncommissioned',
    sortOrder: 'Sort Order',
    source: 'Source',
    sourceId: 'Source ID',
    sourceType: 'Source Type',
    sourceTypes: 'Source Types',
    sources: 'Sources',
    subtype: 'Subtype',
    spinRate: 'Spin Rate',
    stacker: 'Stacker',
    start: 'Start',
    startDate: 'Start Date',
    startService: 'Start Service',
    state: 'State',
    status: 'Status',
    submit: 'Submit',
    subtotals: 'Subtotals',
    superUser: 'Super User',
    sweepsCoins: 'Sweeps Coins',
    sweepsKiosk: 'Sweeps Kiosk',
    targetDateTime: 'Target Date Time',
    terminalId: 'Device ID',
    terminalSubtype: 'Device Subtype',
    terminalType: 'Device Type',
    terminalTypeDescription: 'Device Type Description',
    territory: 'Territory',
    thirdPartyIP: '3rd Party IP Address',
    thisWeek: 'This Week',
    timeStamp: 'Time Stamp',
    title: 'Title',
    today: 'Today',
    topWin: 'Top Win',
    total: 'Total',
    totalWins: 'Total Wins',
    transaction: 'Transaction',
    transactions: 'Transactions',
    transactionId: 'Transaction ID',
    transactionType: 'Transaction Type',
    transactionTypes: 'Transaction Types',
    trx: 'TRX',
    true: 'True',
    trueRouteId: 'TrueRoute ID',
    type: 'Type',
    unassign: 'Unassign',
    unknown: 'Unknown',
    update: 'Update',
    updateColumns: 'Update Columns',
    updateComplete: 'Update Complete',
    updateFailed: 'Update Failed',
    updateInfo: 'Update Info',
    updateLocations: 'Update Locations',
    updateRole: 'Update Role',
    updateSource: 'Update Source',
    uploadCSV: 'Upload CSV',
    updateUserLocations: 'Update User Locations',
    url: 'URL',
    user: 'User',
    userId: 'User ID',
    username: 'Username',
    users: 'Users',
    usersAssignedRole: 'Users Assigned this Role',
    value: 'Value',
    vendor: 'Vendor',
    version: 'Version',
    view: 'View',
    vtp: 'VTP',
    warning: 'Warning',
    warningForDeleteManifest: 'Are you sure you want to delete this manifest?',
    warningForDeleteSource: 'Are you sure you want to delete this source?',
    warningForSameLocation: 'Source location can not be the same as destination one',
    winCount: 'Win Count',
    yesterday: 'Yesterday',
    yourCompany: 'Your Company',
    yourProfile: 'Your Profile',
    yearToDate: 'Year to Date',
    zip: 'Zip',
    defaultRowCount: 10,
    rowCounts: [10, 20, 50, 100]
}

export const capText = {
    asc: 'ASC',
    desc: 'DESC',
    pin: 'PIN',
    warning: 'WARNING',
}