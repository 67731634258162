import './userSettings.scss';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { isValid } from 'components/shared/componentUtils';
import { text as t } from 'shared/text';
import useAuth from 'hooks/Auth/useAuth';
import useInputs from 'components/hooks/Inputs/useInputs';
import useProfile from 'hooks/Profile/useProfile';
import useUtils from 'hooks/useUtils';
import Checkbox from 'components/Checkbox/Checkbox';
import Page from 'app-components/Page/Page';

const UserSettings = () => {
    const { id } = useParams();
    const { getAuth, settings, changeSettings } = useAuth();
    const { inputValues, changeInputValues } = useInputs();
    const { setHeader, userTabs } = useProfile();
    const { callAPI, updateUserTabs } = useUtils();
    const yourProfile = getAuth()?.id === id;
    const modeId = 'darkMode';

    useEffect(() => {
        changeInputValues(settings);
        updateUserTabs(id);
        callAPI('userDetails', userDetailsCallback, {id: id});
    }, []);

    useEffect(()=> {
        isValid(inputValues?.[modeId]) && changeSettings(modeId, inputValues?.[modeId])
    }, [inputValues])

    const userDetailsCallback = (data) => {
        if (data?.isSuccessful) {
            const user = data.webPortalUser;
            const header = yourProfile ? t.yourProfile : user.name ? user.name : user.email;
            setHeader(header);
        }
    }

    return (
        <Page
            classes='user-settings'
            subPage={t.settings}
            tabs={userTabs}
            contentClasses='grid'
        >
            <Checkbox
                id= {modeId}
                classes='sixth'
                label={t.darkMode}
            />
        </Page>
    )
}

export default UserSettings;
