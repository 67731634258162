import { createContext, useState } from 'react';

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
    const [ auth, setAuth ] = useState();
    const [ frozen, setFrozen ] = useState();
    const [ settings, setSettings ] = useState(localStorage.getItem('settings') ? JSON.parse(localStorage.getItem('settings')) : {});

    return (
        <AuthContext.Provider value={{ 
            auth, setAuth,
            frozen, setFrozen,
            settings, setSettings
        }}>
            {children}
        </AuthContext.Provider>
    )
}

export default AuthContext;
