import './tableTotals.scss';
import { addCellClasses, formatContent } from '../../tableUtils';
import { deepCopy, ifElse } from 'components/shared/componentUtils';
import useTables from '../TableHooks/useTables';
         
const TableTotals = ({id, layout, resultsValues, columns, classes, loading}) => {
    const { tableTotals } = useTables();
    const subtotals = layout?.subtotals;
    const resultType = layout?.subtotals?.fields;

    const buildTotalRow = () => {
        if (!tableTotals?.[id] || !subtotals) {return};
        const resultData = layout.subtotals;
        const formats = resultData.formats;
        const newLayouts = deepCopy(layout);
        newLayouts.formats = formats;
        if (resultData.layouts) {newLayouts.layouts = resultData.layouts};
        const totals = tableTotals[id];
        return columns.map((column, i) => {
            const content =  resultType[column] === 'none' ? '' : formatContent(resultsValues?.[column] ? resultsValues[column] : totals[column], column, newLayouts, true);
            const cellClasses = addCellClasses(column, layout, content ? content : 'filler', null);
            return <div key={i} className={`table-cell ${cellClasses}`}>
                <div className={`table-cell-content total`}>
                    {content}
                </div>
            </div>
        })
    }

    return <div className={`table-row total-container ${ifElse(classes)} ${!subtotals ? 'small': ''} ${layout?.hideNavBar ? 'no-nav-bar' : ''}`}>
        <div className={`table-row total ${loading ? 'hide' : ''}`}>
            {buildTotalRow()}
        </div>
    </div>
}

export default TableTotals;
