import { text as t } from 'shared/text'

export const locationTransactionsReportLayout = {
    defaultSort:  {
        orderBy: 'createdOn',
        desc: true,
    },

    tableHeaders: {
        id: t.transactionId,
        terminalId: t.terminalId,
        terminalType: t.terminalType,
        phoneNumber: t.phoneNumber,
        transactionType: t.transactionType,
        cashIn: t.cashIn,
        cashOut: t.cashOut, 
        netCash: t.revenue,
        createdOn: t.createdOn,
        receipt: t.receipt
    },

    sizes: {
        id: 'mid',
        terminalId: 'mid',
        terminalType: 'mid',
        phoneNumber: 'large',
        transactionType: 'large',
        cashIn: 'mid',
        cashOut: 'mid', 
        netCash: 'xl',
        createdOn: 'xl',
        receipt: 'small'
    },
    
    layouts: {
        id: 'text-left phone',
        terminalId: 'text-left',
        terminalType: 'text-left phone',
        phoneNumber: 'text-left phone',
        transactionType: 'text-left',
        cashIn: 'blank text-right phone',
        cashOut: 'blank text-right phone', 
        netCash: 'blank text-right',
        createdOn: 'text-left',
        receipt: 'phone'
    },

    formats: {
        phoneNumber: 'lastFour',
        cashIn: 'currency',
        cashOut: 'currency', 
        netCash: 'currency',
        createdOn: 'date-standard'
    },

    navigation: {
        terminalId: {
            label: 'terminalId',
            data:  [
                {type: 'text', val: 'device'},
                {type: 'prop', val: 'terminalId'}
            ]
        },
        receipt: {
            icon: 'receipt',
            onClick: true,
            validate: {
                type: 'prop',
                val: 's3Url'
            }
        }
    },

    hideSortArrows: {
        netCash: true
    },

    subtotals: {
        groupBy: 'all',
        phoneHeader: 'terminalId',
        fields: {
            id: 'header',
            cashIn: 'total',
            cashOut: 'total',
            netCash: {
                first: {column:'cashIn', type: 'total'},
                 calc: 'add',
                 second: {column:'cashOut', type: 'total'}
             },
        }, 
        layouts: {
            id: 'text-left',
            cashIn: 'text-right',
            cashOut: 'text-right',
            netCash: 'text-right'
        },
        formats: {
            cashIn: 'currency',
            cashOut: 'currency',
            netCash: 'currency'
        }
    }
}
