import 'modals/FlowModals/ConfirmModal/confirmModal.scss';
import { text as t } from 'shared/text';
import useModal from 'components/hooks/Modal/useModal';
import Button from 'components/Button/Button';
import Modal from 'components/Modal/Modal';

const ConfirmModal = ({header, content, onConfirm, onCancel, warning}) => {
    const { removeModal } = useModal();

    const onConfirmClick = (setLoading) => {
        onConfirm(setLoading);
    }
    const onCancelClick = () => {
        if(onCancel) {
            onCancel();
        }
        removeModal(false);
    }

    return (
        <Modal title={header} classes='confirm-modal' warning={warning}>
            <div className='grid'>
                {content && <div className='confirm-modal-message full centered'>{content}</div>}
                <div className='full row spaced'>
                    <Button 
                        classes='green modal-button' 
                        type='submit'
                        onClick={(event, setLoading)=>{onConfirmClick(setLoading)}}>{t.confirm}</Button>
                    <Button 
                        classes='red modal-button' 
                        onClick={onCancelClick}>{t.cancel}</Button>
                </div>
            </div>
        </Modal>
    )
}

export default ConfirmModal;
